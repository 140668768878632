import { useEffect, useState } from "react";
import CarBox from "./CarBox";
import { CAR_DATA } from "./CarData";
import axiosClient from "../axios-client";
import { useTranslation } from "react-i18next";

function PickCar() {
  const { t } = useTranslation();
  const [carLoad, setCarLoad] = useState(true);

  const getCarInfo = (id) => {
    return dataCarsNames.filter((e) => e.id_car === id);
  };

  const [dataCarsNames, setDataCarsNames] = useState([]);

  useEffect(() => {
    axiosClient.get("/cars/").then((res) => {
      setDataCarsNames(res.data);
      setIdCar(res.data[0].id_car);
      setColorBtn(res.data[0].id_car);
      getCarInfo(res.data[0].id_car);
    });
  }, []);

  const [idCar, setIdCar] = useState(1);
  const [colorBtn, setColorBtn] = useState(1);

  const btnID = (id) => {
    setColorBtn(colorBtn === id ? "" : id);
  };

  const coloringButton = (id) => {
    return colorBtn === id ? "colored-button" : "";
  };

  // console.log(getCarInfo(1));

  return (
    <>
      <section className="pick-section">
        <div className="container">
          <div className="pick-container">
            <div className="pick-container__title">
              <h3>{t("Modèles de véhicules")}</h3>
              <h2>{t("Notre flotte de location")}</h2>
              <p>
                {t("Choisissez parmi une variété de nos incroyables véhicules à louer pour votre prochaine aventure ou voyage d'affaires")}
              </p>
            </div>
            <div className="pick-container__car-content">
              {/* pick car */}
              <div className="pick-box">
                {dataCarsNames.map((val, key) => {
                  return (
                    <button
                      key={key}
                      className={`${coloringButton(val.id_car)}`}
                      onClick={() => {
                        btnID(val.id_car);
                        setIdCar(val.id_car);
                      }}
                    >
                      {val.brand} - {val.model}
                    </button>
                  );
                })}
              </div>

              {idCar && (
                <>
                  <div className="box-cars">
                    {/* car */}
                    <div className="pick-car">
                      {carLoad && <span className="loader"></span>}
                      <img
                        style={{ display: carLoad ? "none" : "block" }}
                        src={`${getCarInfo(idCar)[0]?.image}`}
                        alt="car_img"
                        onLoad={() => setCarLoad(false)}
                      />
                    </div>
                    {/* description */}
                    <div className="pick-description">
                      <div className="pick-description__price" style={{
                        textAlign: "center",
                        width:'100%',
                      }}>
                        {t("Détails du véhicule")}
                      </div>
                      <div className="pick-description__table">
                        <div className="pick-description__table__col">
                          <span>{t("Marque")}</span>
                          <span>{getCarInfo(idCar)[0]?.brand}</span>
                        </div>

                        <div className="pick-description__table__col">
                          <span>{t("Modèle")}</span>
                          <span>{getCarInfo(idCar)[0]?.model}</span>
                        </div>

                        <div className="pick-description__table__col">
                          <span>{t("Année")}</span>
                          <span>{getCarInfo(idCar)[0]?.year}</span>
                        </div>

                        <div className="pick-description__table__col">
                          <span>{t("Passagers")}</span>
                          <span>{getCarInfo(idCar)[0]?.passenger_number}</span>
                        </div>

                        <div className="pick-description__table__col">
                          <span>AC</span>
                          <span>
                            {getCarInfo(idCar)[0]?.air_conditioning
                              ? "OUI"
                              : "NON"}
                          </span>
                        </div>

                        <div className="pick-description__table__col">
                          <span>Transmission</span>
                          <span>{getCarInfo(idCar)[0]?.gearBox}</span>
                        </div>

                        <div className="pick-description__table__col">
                          <span>{t("Carburant")}</span>
                          <span>{getCarInfo(idCar)[0]?.fuel}</span>
                        </div>
                      </div>
                      {/* btn cta */}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PickCar;
