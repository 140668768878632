import MainImg from "../images/chooseUs/main.png";
import Box1 from "../images/chooseUs/icon1.png";
import Box2 from "../images/chooseUs/icon2.png";
import Box3 from "../images/chooseUs/icon3.png";
import { useTranslation } from "react-i18next";

function ChooseUs() {
  const { t } = useTranslation();
  return (
    <>
      <section className="choose-section">
        <div className="container">
          <div className="choose-container">
            {/* <img
              className="choose-container__img"
              src={MainImg}
              alt="car_img"
            /> */}
            <div className="text-container">
              <div className="text-container__left">
                <h4>{t("Pourquoi nous choisir")}</h4>
                <h2>{t("Les meilleures offres que vous puissiez trouver")}</h2>
                <p style={{
                  fontSize:20,
                  textAlign:'justify'
                }}>
                  {t("Choisir notre service de location de voitures va au-delà de la simple commodité. Nous nous démarquons en offrant une expérience sans contraintes, avec une caution gratuite qui soulage le budget de nos clients en vacances. Notre engagement envers votre tranquillité d'esprit se reflète dans cette particularité unique. Chez nous, chaque kilomètre est une aventure sans soucis. En plus de cela, nous vous proposons les meilleures offres du marché, garantissant que votre exploration du Maroc soit non seulement mémorable mais aussi économiquement avantageuse. Faites le choix intelligent, optez pour la liberté sans limites avec notre service de location de voitures")}.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ChooseUs;
