import { useTranslation } from "react-i18next";

function Banner() {
  const { t } = useTranslation();
  return (
    <>
      <section className="banner-section">
        <div className="container">
          <div className="banner-content">
            <div className="banner-content__text">
              <h2>
                {t("Profitez de tarifs avantageux avec notre service de location de voitures économique.")} 
              </h2>
              <p>
                {t("Principaux aéroports. Fournisseurs locaux.")} <span>24/7</span>{" "}
                Support.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Banner;
