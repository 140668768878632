import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import axiosClient from "../axios-client";
import { useState } from "react";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Contact() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [nom, setNom] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");

  const addContact = () => {
    
    const formData = new FormData();
    formData.append("nom", nom);
    formData.append("email", email);
    formData.append("comment", comment);
    formData.append("is_check", false);

    axiosClient.post("/contacts/", formData);
  };

  return (
    <>
      <section className="contact-page">
        <HeroPages name="Contact" />
        <div className="container">
          <div className="contact-div">
            <div className="contact-div__text">
              <h2>{t("Besoin d'informations complémentaires")}</h2>
              <p>
                {t("Pour toute question relative à votre réservation, votre location de voiture, n'hésitez pas à nous contacter via nos différents points de contact. Nos équipes sont heureuses de vous répondre")}.
              </p>
              <p>
                <i className="fa-solid fa-phone"></i>&nbsp; (+212) 662-024-423
              </p>
              <p>
                <i className="fa-solid fa-envelope"></i>&nbsp;
                contact@australiecars.com
              </p>
              <p>
                <i className="fa-solid fa-location-dot"></i>&nbsp; AGADIR, MAROC
              </p>
            </div>
            <div className="contact-div__form">
              <form onSubmit={addContact}>
                <label>
                  {t("Nom complet")} <b>*</b>
                </label>
                <input
                  type="text"
                  placeholder='E.g: "Joe Shmoe"'
                  onChange={(e) => setNom(e.target.value)}
                ></input>

                <label>
                  Email <b>*</b>
                </label>
                <input
                  type="email"
                  placeholder="youremail@example.com"
                  onChange={(e) => setEmail(e.target.value)}
                ></input>

                <label>
                  {t("Parlez-nous en")} <b>*</b>
                </label>
                <textarea
                  placeholder={t("Ecrire ici..")}
                  onChange={(e) => setComment(e.target.value)}
                ></textarea>

                <button type="submit">
                  <i className="fa-solid fa-envelope-open-text"></i>&nbsp;
                  {t("Envoyer Message")}
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
              <h2>Book a car by getting in touch with us</h2>
              <span>
                <i className="fa-solid fa-phone"></i>
                <h3>(123) 456-7869</h3>
              </span>
            </div>
          </div>
        </div> */}
        <Footer />
      </section>
    </>
  );
}

export default Contact;
