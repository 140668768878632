import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import CarImg1 from "../images/cars-big/audi-box.png";
import CarImg2 from "../images/cars-big/golf6-box.png";
import CarImg3 from "../images/cars-big/toyota-box.png";
import CarImg4 from "../images/cars-big/bmw-box.png";
import CarImg5 from "../images/cars-big/benz-box.png";
import CarImg6 from "../images/cars-big/passat-box.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosClient from "../axios-client";
import { TbListDetails } from "react-icons/tb";
import { LiaTimesSolid } from "react-icons/lia";
import { FaCheck } from "react-icons/fa";
import { GrStatusWarning } from "react-icons/gr";
import { useTranslation } from "react-i18next";

function Models() {
  const { t } = useTranslation();
  
  const [dataCars, setDataCars] = useState([]);
  useEffect(() => {
    // if (nextStep) {
    //   if (chechBooking.length > 0) {
    //     let CarsBooking=[]
    //     for (let index = 0; index < chechBooking.length; index++) {
    //       CarsBooking.push(chechBooking[index]?.car)
    //     }
    //     console.log('cars booking : ',CarsBooking);
    //     axiosClient.get('/cars/').then(res=>{
    //       setDataCars(res.data.filter(e=>!CarsBooking.includes(e.id_car)))
    //     })
    //   }
    // }

    axiosClient.get("/cars/").then((res) => {
      setDataCars(res.data);
    });
  }, []);

  
  const [modal, setModal] = useState(false); //  class - active-modal

  const showDetails = () => {
    setModal(!modal);
    const modalDiv = document.querySelector(".booking-modal");
    modalDiv.scroll(0, 0);
  };
  
  useEffect(() => {
    if (modal === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modal]);

  const [idCar, setIdCar] = useState();
  const detailsCar = (id) => {
    const dataCar = dataCars.find((e) => e.id_car === id);
    return dataCar;
  };

  
  return (
    <>
      <section className="models-section">
        <HeroPages name={t("Voitures")} />
        <div className="container">
          <div className="models-div">
            
          {dataCars.map((val, key) => {
              return (
                <div className="models-div__box">
                  <div className="models-div__box__img">
                    <img src={val.image} alt="car_img" />
                    <div className="models-div__box__descr">
                      <div className="models-div__box__descr__name-price">
                        <div
                          className="models-div__box__descr__name-price__name"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <p>
                            {val.brand} {val.model}
                          </p>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: 12,
                              gap: 4,
                              padding: "4px 6px",
                              border: "1px solid gray",
                              borderRadius: 7,
                              cursor: "pointer",
                              boxShadow: "1px 1px 5px gray",
                            }}
                            onClick={() => {
                              showDetails();
                              setIdCar(val.id_car);
                            }}
                          >
                            <TbListDetails
                              className="btn-showDetails"
                              size={12}
                            />
                            <span>{t("Détails")}</span>
                          </div>
                        </div>
                        {/* <div className="models-div__box__descr__name-price__price">
                      <h4>$45</h4>
                      <p>per day</p>
                    </div> */}
                      </div>
                      <div className="models-div__box__descr__name-price__details" style={{
                        borderBottom:0
                      }}>
                        <span>{val.passenger_number} {t("Sièges")}</span>
                        <span style={{ textAlign: "right" }}>
                          {t("Année")} {val.year}
                        </span>
                        <span>{val.gearBox}</span>
                        <span style={{ textAlign: "right" }}>{val.fuel}</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
              <h2>Book a car by getting in touch with us</h2>
              <span>
                <i className="fa-solid fa-phone"></i>
                <h3>(123) 456-7869</h3>
              </span>
            </div>
          </div>
        </div> */}
        <Footer />
      </section>
      

      {/* modal ------------------------------------ */}

      <div className={`booking-modal ${modal ? "active-modal" : ""}`}>
        {/* title */}
        <div className="booking-modal__title">
          <h2>{t("Détails & spécification")}</h2>
          <i onClick={showDetails} className="">
            <LiaTimesSolid />
          </i>
        </div>
        {/* message */}
        {/* <div className="booking-modal__message">
          <h4>
            <i className="fa-solid fa-circle-info"></i> Upon completing this
            reservation enquiry, you will receive:
          </h4>
          <p>
            Your rental voucher to produce on arrival at the rental desk and a
            toll-free customer support number.
          </p>
        </div> */}
        {/* car info */}
        <div
          className=""
          style={{
            padding: 20,
          }}
        >
          <h5 style={{ textTransform: "uppercase", fontSize: 25 }}>
            {t("Spécification")}
          </h5>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: 15,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 6,
                fontSize: 13,
              }}
            >
              <span>{detailsCar(idCar)?.passenger_number} {t("Sièges")}</span>
              <span>{t("Couleur")} : {detailsCar(idCar)?.color}</span>
              <span>{t("Année")} : {detailsCar(idCar)?.year}</span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 6,
                fontSize: 13,
              }}
            >
              <span>{detailsCar(idCar)?.gearBox}</span>
              <span>
                {t("Climatisation")} :{" "}
                {detailsCar(idCar)?.air_conditioning === true
                  ? "Disponible"
                  : "Indisponible"}
              </span>
              <span>{t("Carburant")} : {detailsCar(idCar)?.fuel}</span>
            </div>
          </div>
        </div>
        <div
          className=""
          style={{
            padding: 20,
          }}
        >
          <h5 style={{ textTransform: "uppercase", fontSize: 25 }}>
            {t("Prix inclut")}
          </h5>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 6,
              padding: 15,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 4,
                alignItems: "center",
                fontSize: 13,
              }}
            >
              <FaCheck color="pink" />
              <span>{t("Caution gratuite")}</span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 4,
                alignItems: "center",
                fontSize: 13,
              }}
            >
              <FaCheck color="pink" />
              <span>{t("Kilométrage illimité")}</span>
            </div>
          </div>
        </div>
        <div
          className=""
          style={{
            padding: 20,
          }}
        >
          <h5 style={{ textTransform: "uppercase", fontSize: 25 }}>
            Conditions
          </h5>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 6,
              padding: 15,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 4,
                alignItems: "center",
                fontSize: 13,
              }}
            >
              <GrStatusWarning color="black" />
              <span>{t("Âge minimum 23")}</span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 4,
                alignItems: "center",
                fontSize: 13,
              }}
            >
              <GrStatusWarning color="black" />
              <span>{t("CIN / Passeport")}</span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 4,
                alignItems: "center",
                fontSize: 13,
              }}
            >
              <GrStatusWarning color="black" />
              <span>{t("Permis de conduire")}</span>
            </div>
          </div>
        </div>
        {/* 
        <div className="booking-modal__car-info">
          <div className="dates-div">
            <div className="booking-modal__car-info__dates">
              <h5>Location & Date</h5>
              <span>
                <i className="fa-solid fa-location-dot"></i>
                <div>
                  <h6>Pick-Up Date & Time</h6>
                  <p>
                    pick time /{" "}
                    <input type="time" className="input-time"></input>
                  </p>
                </div>
              </span>
            </div>

            <div className="booking-modal__car-info__dates">
              <span>
                <i className="fa-solid fa-location-dot"></i>
                <div>
                  <h6>Drop-Off Date & Time</h6>
                  <p>
                    drop time /{" "}
                    <input type="time" className="input-time"></input>
                  </p>
                </div>
              </span>
            </div>

            <div className="booking-modal__car-info__dates">
              <span>
                <i className="fa-solid fa-calendar-days"></i>
                <div>
                  <h6>Pick-Up Location</h6>
                  <p>pick-up location</p>
                </div>
              </span>
            </div>

            <div className="booking-modal__car-info__dates">
              <span>
                <i className="fa-solid fa-calendar-days"></i>
                <div>
                  <h6>Drop-Off Location</h6>
                  <p>drop off</p>
                </div>
              </span>
            </div>
          </div>
          <div className="booking-modal__car-info__model">
            <h5>
              <span>Car -</span> cat type
            </h5>
            image
          </div>
        </div> */}
        {/* personal info */}
        {/* <div className="booking-modal__person-info">
          <h4>Personal Information</h4>
          <form className="info-form">
            <div className="info-form__2col">
              <span>
                <label>
                  First Name <b>*</b>
                </label>
                <input
                  value={name}
                  onChange={handleName}
                  type="text"
                  placeholder="Enter your first name"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Last Name <b>*</b>
                </label>
                <input
                  value={lastName}
                  onChange={handleLastName}
                  type="text"
                  placeholder="Enter your last name"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>

              <span>
                <label>
                  Phone Number <b>*</b>
                </label>
                <input
                  value={phone}
                  onChange={handlePhone}
                  type="tel"
                  placeholder="Enter your phone number"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Age <b>*</b>
                </label>
                <input
                  value={age}
                  onChange={handleAge}
                  type="number"
                  placeholder="18"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
            </div>

            <div className="info-form__1col">
              <span>
                <label>
                  Email <b>*</b>
                </label>
                <input
                  value={email}
                  onChange={handleEmail}
                  type="email"
                  placeholder="Enter your email address"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Address <b>*</b>
                </label>
                <input
                  value={address}
                  onChange={handleAddress}
                  type="text"
                  placeholder="Enter your street address"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
            </div>

            <div className="info-form__2col">
              <span>
                <label>
                  City <b>*</b>
                </label>
                <input
                  value={city}
                  onChange={handleCity}
                  type="text"
                  placeholder="Enter your city"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Zip Code <b>*</b>
                </label>
                <input
                  value={zipcode}
                  onChange={handleZip}
                  type="text"
                  placeholder="Enter your zip code"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
            </div>

            <span className="info-form__checkbox">
              <input type="checkbox"></input>
              <p>Please send me latest news and updates</p>
            </span>

          </form>
        </div> */}
      </div>
    </>
  );
}

export default Models;
