import React, { useEffect, useState } from "react";
import HeroPages from "../components/HeroPages";
import { useLocation, useNavigate } from "react-router-dom";
import { TbUsersPlus } from "react-icons/tb";
import { PiBaby } from "react-icons/pi";
import { BiPlus, BiMinus, BiArrowBack } from "react-icons/bi";
import { BsFuelPump } from "react-icons/bs";
import { GoAlert } from "react-icons/go";
import { GiCheckedShield } from "react-icons/gi";
import { FaCcMastercard } from "react-icons/fa";
import { FaCcVisa } from "react-icons/fa6";
import { SiHomeassistantcommunitystore } from "react-icons/si";
import { GiTakeMyMoney } from "react-icons/gi";
import { GiReceiveMoney } from "react-icons/gi";

import Footer from "../components/Footer";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

const myStyles = {
  confirmReservation: {
    transition: "0.4s ease",
    padding: "5px 15px",
    borderRadius: "20px",
    border: "1px solid #eb5151",
    fontSize: 13,
    textDecoration: "none",
    background: "#eb5151",
    cursor: "pointer",
    textTransform: "uppercase",
    color: "white",
    "&:hover": {
      background: "white",
      border: "1px solid #eb5151",
      color: "#eb5151",
    },
  },
};

function Reservation() {
  const { t } = useTranslation();
  const location = useLocation();
  const [price, setPrice] = useState();
  useEffect(() => {
    setPrice(parseFloat(location.state.price));
  }, []);

  const [pickUp, dropOff, pickDate, pickTime, dropDate, dropTime, days, idCar] =
    [
      location.state.pickUp,
      location.state.dropOff,
      location.state.pickDate,
      location.state.pickTime,
      location.state.dropDate,
      location.state.dropTime,
      location.state.days,
      location.state.idCar,
    ];

  const [conducteurSup, setConducteurSup] = useState(false);
  const [rehausseur, setRehausseur] = useState(0);
  const [siegeEnfant, setSiegeEnfant] = useState(0);
  const [assurance, setAssurance] = useState(false);

  const [priceFixed, setPriceFixed] = useState(false);
  const [widthPage, setWitdhPage] = useState(window.innerWidth);

  const [showCordonnees, setShowCordonnees] = useState(false);

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    const onPageScroll = () => {
      if (window.pageYOffset > 400) {
        setPriceFixed(true);
      } else {
        setPriceFixed(false);
      }
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);

  useEffect(() => {
    function handleResize() {
      setWitdhPage(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
  }, []);

  const navigate = useNavigate();
  const test = () => {
    navigate("/search");
  };

  return (
    <section className="" style={{ position: "relative" }}>
      <HeroPages name={t("Réservation")} />
      <div
        style={{
          position: priceFixed && "fixed",
          top: priceFixed && 10,
          // right: priceFixed && 0,
          width: "100%",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div
          style={{
            background: "#b1b1b1",
            padding: "4px 15px",
            borderRadius: "0px 7px 0px 7px",
            fontSize: 17,
            color: "black",
            display: "block",
            width: "auto",
            fontWeight: "bold",
          }}
        >
          {price} MAD (H.T)
        </div>
      </div>
      <div>
        <div
          style={{
            textAlign: "center",
            fontSize: 22,
            margin: "10px 0px 10px 0px",
          }}
        >
          <h1>{t("OPTIONS SUPPLÉMENTAIRES")}</h1>
        </div>
        <div style={{ padding: 20, fontSize: 13 }}>
          <span>
            {t("Sélectionnez des options supplémentaires pour profiter au mieux du voyage. En réservant directement avec nous, vous pouvez réserver à l’avance tous les équipements et services supplémentaires dont vous avez besoin. Si vous réservez par un autre moyen, ils seront sous réserve de disponibilité")}.
          </span>
        </div>
        <div style={{ padding: 20, fontSize: 16, fontWeight: "bold" }}>
          <span>{t("Nous vous recommandons les options suivantes")} :</span>
        </div>
        <div
          style={{
            border: "1px solid #d6d6d6",
            padding: "10px 15px",
            margin: "5px 15px",
            borderRadius: 10,
            boxShadow: "5px 5px 40px #d6d6d6",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                fontSize: 22,
                display: "flex",
                alignItems: "center",
                gap: 7,
              }}
            >
              <TbUsersPlus />
              <span>{t("Conducteur supplémentaire")}</span>
            </div>
            <div>
              <span style={{ fontWeight: "bold", fontSize: 14 }}>
                50,00 MAD / <span>{t("Par jour")}</span>
              </span>
            </div>
          </div>
          <div
            style={{
              marginTop: 4,
              marginBottom: 4,
              fontSize: 15,
            }}
          >
            <p>Le prix sera confirmé lors du retrait du véhicule.</p>
            <p>
              {t("Veuillez vous assurer que chaque conducteur ait son permis de conduite")}.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "right",
            }}
          >
            {!conducteurSup ? (
              <button
                style={{
                  padding: "4px 15px",
                  borderRadius: "20px",
                  border: "1px solid #eb5151",
                  fontSize: 20,
                  textDecoration: "none",
                  background: "white",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setPrice(price + 50.0 * days);
                  setConducteurSup(!conducteurSup);
                  toast.success(
                    <span style={{ fontSize: 15 }}>
                      {t("Vous avez ajouté le conducteur supplémentaire")}
                    </span>,
                    {
                      position: "top-center",
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    }
                  );
                }}
              >
                {t("Ajouter")}
              </button>
            ) : (
              <button
                style={{
                  padding: "4px 15px",
                  borderRadius: "20px",
                  border: "1px solid #eb5151",
                  fontSize: 20,
                  textDecoration: "none",
                  background: "white",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setPrice(price - 50.0 * days);
                  setConducteurSup(!conducteurSup);
                  toast.error(
                    <span style={{ fontSize: 15 }}>
                      {t("Vous avez retirer le conducteur supplémentaire")}
                    </span>,
                    {
                      position: "top-center",
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    }
                  );
                }}
              >
                {t("Supprimer")}
              </button>
            )}
          </div>
        </div>
        <div style={{ padding: 20, fontSize: 16, fontWeight: "bold" }}>
          <span>{t("Sièges bébé/enfant")} :</span>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: widthPage <= 800 && "column",
          }}
        >
          <div
            style={{
              border: "1px solid #d6d6d6",
              padding: "10px 15px",
              margin: "5px 15px",
              borderRadius: 10,
              boxShadow: "5px 5px 40px #d6d6d6",
              minHeight: 222,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontSize: 22,
                  display: "flex",
                  alignItems: "center",
                  gap: 7,
                }}
              >
                <PiBaby />
                <span>{t("Rehausseur")}</span>
              </div>
              <div>
                <span style={{ fontWeight: "bold", fontSize: 14 }}>
                  50,00 MAD / <span>{t("Par jour")}</span>
                </span>
              </div>
            </div>
            <div
              style={{
                marginTop: 4,
                marginBottom: 4,
                fontSize: 15,
              }}
            >
              <p>
                {t("Le rehausseur surélève l’enfant pour une meilleure adaptation à la ceinture de sécurité")}.
              </p>
              <br />
              <p>
                {t("Il convient pour les enfants de 8 à 11 ans pesant entre 20 et 45 kg. Le rehausseur peut être obligatoire dans certains pays")}.
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <BiMinus
                size={22}
                cursor={rehausseur > 0 ? "pointer" : "no-drop"}
                color={rehausseur > 0 ? "#eb5151" : "#eb515185"}
                onClick={(e) => {
                  if (rehausseur > 0) {
                    setRehausseur(rehausseur - 1);
                    setPrice(price - 50.0 * days);
                    toast.error(
                      <span style={{ fontSize: 15 }}>
                        {t("Vous avez supprimé un rehausseur")}
                      </span>,
                      {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      }
                    );
                  }
                }}
              />
              <div
                style={{
                  height: 45,
                  width: 45,
                  border: "1px solid #eb5151",
                  display: "flex",
                  padding: 0,
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 18,
                  color: "#eb5151",
                  borderRadius: 7,
                }}
              >
                <span>{rehausseur}</span>
              </div>
              <BiPlus
                size={22}
                cursor={rehausseur < 3 ? "pointer" : "no-drop"}
                color={rehausseur < 3 ? "#eb5151" : "#eb515185"}
                onClick={(e) => {
                  if (rehausseur < 3) {
                    setRehausseur(rehausseur + 1);
                    setPrice(price + 50.0 * days);
                    toast.success(
                      <span style={{ fontSize: 15 }}>
                        {t("Vous avez ajouté un rehausseur")}
                      </span>,
                      {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      }
                    );
                  }
                }}
              />
            </div>
          </div>
          <div
            style={{
              border: "1px solid #d6d6d6",
              padding: "10px 15px",
              margin: "5px 15px",
              borderRadius: 10,
              boxShadow: "5px 5px 40px #d6d6d6",
              minHeight: 222,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontSize: 22,
                  display: "flex",
                  alignItems: "center",
                  gap: 7,
                }}
              >
                <PiBaby />
                <span>{t("Siège enfant")}</span>
              </div>
              <div>
                <span style={{ fontWeight: "bold", fontSize: 14 }}>
                  50,00 MAD / <span>{t("Par jour")}</span>
                </span>
              </div>
            </div>
            <div
              style={{
                marginTop: 4,
                marginBottom: 4,
                fontSize: 15,
              }}
            >
              <p>
                {t("Optez pour les sièges enfants pour la sécurité de vos tout-petits")}.
              </p>
              <br />
              
              <p>
                {t("Ils sont adaptés pour les enfants âgés de 4 à 11 ans d’un poids allant de 15 à 36 kg. Ils peuvent être obligatoires dans certains pays")}.
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <BiMinus
                size={22}
                cursor={siegeEnfant > 0 ? "pointer" : "no-drop"}
                color={siegeEnfant > 0 ? "#eb5151" : "#eb515185"}
                onClick={(e) => {
                  if (siegeEnfant > 0) {
                    setSiegeEnfant(siegeEnfant - 1);
                    setPrice(price - 50.0 * days);
                    toast.error(
                      <span style={{ fontSize: 15 }}>
                        {t("Vous avez supprimé un siège d'enfant")}
                      </span>,
                      {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      }
                    );
                  }
                }}
              />
              <div
                style={{
                  height: 45,
                  width: 45,
                  border: "1px solid #eb5151",
                  display: "flex",
                  padding: 0,
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 18,
                  color: "#eb5151",
                  borderRadius: 7,
                }}
              >
                <span>{siegeEnfant}</span>
              </div>
              <BiPlus
                size={22}
                cursor={siegeEnfant < 3 ? "pointer" : "no-drop"}
                color={siegeEnfant < 3 ? "#eb5151" : "#eb515185"}
                onClick={(e) => {
                  if (siegeEnfant < 3) {
                    setSiegeEnfant(siegeEnfant + 1);
                    setPrice(price + 50.0 * days);
                    toast.success(
                      <span style={{ fontSize: 15 }}>
                        {t("Vous avez ajouté un siège d'enfant")}
                      </span>,
                      {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      }
                    );
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div style={{ padding: 20, fontSize: 16, fontWeight: "bold" }}>
          <span>{t("Assurance")} : </span>
        </div>
        <div
          style={{
            border: "1px solid #d6d6d6",
            padding: "10px 15px",
            margin: "5px 15px",
            borderRadius: 10,
            boxShadow: "5px 5px 40px #d6d6d6",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                fontSize: 22,
                display: "flex",
                alignItems: "center",
                gap: 7,
              }}
            >
              <GiCheckedShield />
              <span>{t("Assurance")}</span>
            </div>
            <div>
              <span style={{ fontWeight: "bold", fontSize: 14 }}>
                150,00 MAD / <span>{t("Par jour")}</span>
              </span>
            </div>
          </div>
          <div
            style={{
              marginTop: 4,
              marginBottom: 4,
              fontSize: 15,
            }}
          >
            <p>{t("Protection contre les dommages et les collisions")}.</p>
            
            <p>{t("Protection bris de glace, phares et pneumatiques")}</p>
            <br />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "right",
            }}
          >
            {!assurance ? (
              <button
                style={{
                  padding: "4px 15px",
                  borderRadius: "20px",
                  border: "1px solid #eb5151",
                  fontSize: 20,
                  textDecoration: "none",
                  background: "white",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setPrice(price + 150.0 * days);
                  setAssurance(!assurance);
                  toast.success(
                    <span style={{ fontSize: 15 }}>
                      {t("Vous avez ajouté l'assurance")}
                    </span>,
                    {
                      position: "top-center",
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    }
                  );
                }}
              >
                {t("Ajouter")}
              </button>
            ) : (
              <button
                style={{
                  padding: "4px 15px",
                  borderRadius: "20px",
                  border: "1px solid #eb5151",
                  fontSize: 20,
                  textDecoration: "none",
                  background: "white",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setPrice(price - 150.0 * days);
                  setAssurance(!assurance);
                  toast.error(
                    <span style={{ fontSize: 15 }}>
                      {t("Vous avez supprimé l'assurance")}
                    </span>,
                    {
                      position: "top-center",
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    }
                  );
                }}
              >
                {t("Supprimer")}
              </button>
            )}
          </div>
        </div>
        <div
          style={{
            marginTop: 15,
            marginRight: 15,
            padding: "10px 15px",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            gap: 7,
          }}
        >
          <div
            style={{
              fontSize: 22,
            }}
          >
            <span>Total : </span>
            <span style={{ fontWeight: "bold" }}>{price} MAD H.T</span>
          </div>
          <button
            style={{
              padding: "4px 15px",
              borderRadius: "20px",
              border: "1px solid #eb5151",
              fontSize: 20,
              textDecoration: "none",
              background: "white",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/verification-et-paiement", {
                state: {
                  price,
                  pickUp,
                  dropOff,
                  pickDate,
                  pickTime,
                  dropDate,
                  dropTime,
                  days,
                  conducteurSup,
                  rehausseur,
                  siegeEnfant,
                  assurance,
                  idCar,
                },
              });

              // setShowCordonnees(true);

              // if (time !== null) {
              //   let timer = setInterval(() => {
              //     setTime((time=600) => {
              //       if (time === 0) {
              //         clearInterval(timer);
              //         if (window.confirm("Votre session est expirée")) {
              //           window.location.href = "/";
              //         } else {
              //           window.location.href = "/";
              //         }
              //         return 0;
              //       } else return time - 1;
              //     });
              //     console.log(timer);
              //   }, 1000);
              // }
            }}
          >
            {t("Suivant")}
          </button>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </section>
  );
}

export default Reservation;
