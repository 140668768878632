import Img2 from "../images/testimonials/pfp1.jpg";
import Img3 from "../images/testimonials/pfp2.jpg";

import { useNavigate } from "react-router-dom";

import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/pagination";

import "./styles.css";

// import required modules
import { EffectCube, Pagination } from "swiper/modules";
import axiosClient from "../axios-client";
import Avatar from "react-avatar";
import { useTranslation } from "react-i18next";

function Testimonials() {
  const { t } = useTranslation();
  const [dataReview, setDataReview] = useState([]);

  useEffect(() => {
    axiosClient.get("/reviews/").then((res) => {
      setDataReview(res.data.slice(0, 5));
    });
  }, []);
  return (
    <>
      <section className="testimonials-section">
        <div className="container">
          <div className="testimonials-content">
            <div className="testimonials-content__title">
              <h4>{t("Expériences partagées par les individus")}</h4>
              <h2>{t("Témoignages de clients")}</h2>
              <p>
                {t("Découvrez l'impact positif que nous avons eu sur nos clients en lisant leurs témoignages. Nos clients ont expérimenté notre service et nos résultats et sont impatients de partager leurs expériences positives avec vous")}.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Swiper
        effect={"cube"}
        grabCursor={true}
        cubeEffect={{
          shadow: true,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94,
        }}
        pagination={true}
        modules={[EffectCube, Pagination]}
        className="swiper"
      >
        {dataReview.map((val, key) => {
          return (
            <SwiperSlide key={key} className="all-testimonials__box">
              <span className="quotes-icon">
                <i className="fa-solid fa-quote-right"></i>
              </span>
              <p>"{val.comment}"</p>
              <div className="all-testimonials__box__name">
                <div className="all-testimonials__box__name__profile">
                  <Avatar
                    name={`${val.first_name.trim()} ${val.last_name.trim()}`}
                    size="60"
                    round={true}
                  />
                  <span>
                    <h4>
                      {val.first_name} {val.last_name}
                    </h4>
                  </span>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}

export default Testimonials;
