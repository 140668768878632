import React, { useEffect, useState } from "react";
import HeroPages from "../components/HeroPages";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TbUsersPlus } from "react-icons/tb";
import { PiBaby } from "react-icons/pi";
import { BiPlus, BiMinus, BiArrowBack } from "react-icons/bi";
import { BsFuelPump } from "react-icons/bs";
import { GoAlert } from "react-icons/go";
import { GiCheckedShield } from "react-icons/gi";
import { FaCcMastercard } from "react-icons/fa";
import { FaCcVisa, FaCircleXmark } from "react-icons/fa6";
import { SiHomeassistantcommunitystore } from "react-icons/si";
import { GiTakeMyMoney } from "react-icons/gi";
import { GiReceiveMoney } from "react-icons/gi";
import axiosClient from "../axios-client";
import Footer from "../components/Footer";
import { MdTimerOff } from "react-icons/md";
import imgCheck from "../images/confirmed/checked.png";
import { useTranslation } from "react-i18next";

function Coordonnees() {
  const { t } = useTranslation();
  const location = useLocation();
  const [price, setPrice] = useState(parseFloat(location.state.price));

  const [
    pickUp,
    dropOff,
    pickDate,
    pickTime,
    dropDate,
    dropTime,
    days,
    conducteurSup,
    rehausseur,
    siegeEnfant,
    assurance,
    idCar,
  ] = [
    location.state.pickUp,
    location.state.dropOff,
    location.state.pickDate,
    location.state.pickTime,
    location.state.dropDate,
    location.state.dropTime,
    location.state.days,
    location.state.conducteurSup,
    location.state.rehausseur,
    location.state.siegeEnfant,
    location.state.assurance,
    location.state.idCar,
  ];

  const [priceFixed, setPriceFixed] = useState(false);
  const [widthPage, setWitdhPage] = useState(window.innerWidth);

  const [showCordonnees, setShowCordonnees] = useState(false);

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    const onPageScroll = () => {
      if (window.pageYOffset > 400) {
        setPriceFixed(true);
      } else {
        setPriceFixed(false);
      }
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);

  useEffect(() => {
    function handleResize() {
      setWitdhPage(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
  }, []);

  // ------------------------------------------------
  const [modal, setModal] = useState(false); //  class - active-modal

  const [modalConfirmed, setModalConfirmed] = useState(false);

  const showDetails = () => {
    setModal(!modal);
    const modalDiv = document.querySelector(".booking-modal");
    modalDiv.scroll(0, 0);
  };

  const showModalConfirmed = () => {
    setModalConfirmed(true);
    const modalDiv = document.querySelector(".booking-modal");
    modalDiv.scroll(0, 0);
  };
  // disable page scroll when modal is displayed
  useEffect(() => {
    if (modal === true || modalConfirmed === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modal, modalConfirmed]);

  const [time, setTime] = useState(600);
  useEffect(() => {
    let timer = setInterval(() => {
      setTime((time) => {
        if (time === 0) {
          clearInterval(timer);
          showDetails();
          window.scrollTo(0, 0);
          return 0;
        } else return time - 1;
      });
    }, 1000);
  }, []);

  // Coordonnées
  const [civilite, setCivilite] = useState("");
  const [nomFamille, setNomFamille] = useState("");
  const [prenom, setPrenom] = useState("");
  const [adressEmail, setAdresseEmail] = useState("");
  const [confirmAdresseEmail, setConfirmAdresseEmail] = useState("");
  const [numTel, setNumTel] = useState("");

  const today = new Date();
  const maxYear = today.getFullYear() - 21;

  const [dateNaissance, setDateNaissance] = useState("");
  const [cinOrPass, setCinOrPass] = useState();
  const [ville, setVille] = useState("");
  const [codePostal, setCodePostal] = useState("");
  const [pays, setPays] = useState("");
  const [typePaiement, setTypePaiement] = useState("cash");

  const navigate = useNavigate();

  const [dataPickupPoint, setDataPickupPoint] = useState([]);
  const [dataCar, setDataCar] = useState([]);
  useEffect(() => {
    axiosClient.get("/pickup-point/").then((res) => {
      setDataPickupPoint(res.data);
    });
    axiosClient.get("/cars/").then((res) => {
      setDataCar(res.data.find((e) => e.id_car === parseInt(idCar)));
    });
  }, []);

  const confirmReservation = () => {
    // e.preventDefault();
    if (
      !civilite ||
      !nomFamille ||
      !prenom ||
      !adressEmail ||
      !confirmAdresseEmail ||
      !numTel ||
      !dateNaissance ||
      !cinOrPass ||
      !ville ||
      !codePostal ||
      !pays ||
      !typePaiement
    ) {
      const errorMsg = document.querySelector(".error-message");
      errorMsg.style.display = "flex";
      window.scroll(0, 0);
      return;
    }

    const newPrice =
      typePaiement === "cash"
        ? price + price * 0.2
        : price + price * 0.2 + price * 0.05;

    const formData = new FormData();
    formData.append("gender", civilite);
    formData.append("first_name", nomFamille);
    formData.append("last_name", prenom);
    formData.append("email", adressEmail);
    formData.append("tel", numTel);
    formData.append("birthday", dateNaissance);
    formData.append("cin_passport", cinOrPass);
    formData.append("ville", ville);
    formData.append("codePostal", codePostal);
    formData.append("pays", pays);
    formData.append("surPlace", typePaiement === "cash" ? true : false);
    formData.append("car", idCar);
    formData.append("assurance", assurance);
    formData.append("childchair", siegeEnfant);
    formData.append("rehausseur", rehausseur);
    formData.append("conducteur", conducteurSup);
    formData.append("start_date", pickDate);
    formData.append("end_date", dropDate);
    formData.append("start_time", pickTime);
    formData.append("end_time", dropTime);
    formData.append("nbr_jrs", days);
    formData.append("pickup_start", pickUp);
    formData.append("pickup_end", dropOff);
    formData.append("payement", false);
    formData.append("total_price", newPrice);
    formData.append("total_price_HT", price);
    axiosClient.post("/booking/", formData).then(() => {
      showModalConfirmed();
      setTime("Stop");
      window.scroll(0, 0);
      // navigate("/");
    });
  };

  return (
    <section className="" style={{ position: "relative" }}>
      <HeroPages name={t("Réservation")} />
      <div
        style={{
          position: priceFixed && "fixed",
          top: priceFixed && 10,
          // right: priceFixed && 0,
          width: "100%",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div
          style={{
            background: "#b1b1b1",
            padding: "4px 15px",
            borderRadius: "0px 7px 0px 7px",
            fontSize: 17,
            color: "black",
            display: "block",
            width: "auto",
            fontWeight: "bold",
          }}
        >
          {typePaiement === "cash"
            ? price + price * 0.2
            : price + price * 0.2 + price * 0.05}{" "}
          MAD (T.T.C)
        </div>
      </div>
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 15,
          }}
        >
          <div
            style={{
              textAlign: "center",
              fontSize: 22,
              margin: "10px 0px 10px 0px",
            }}
          >
            <h1>{t("CONFIRMER VOTRE RÉSERVATION")}</h1>
          </div>
          <div
            style={{
              fontSize: 13,
              border: "1px solid red",
              padding: "7px 15px",
              display: "flex",
              alignItems: "center",
              gap: 5,
              background: "#ffeded",
              borderRadius: 7,
            }}
          >
            <div>
              <GoAlert size={25} color="red" />
            </div>
            <div>
              {t("Votre session expirera dans")}{" "}
              <strong>
                {`${Math.floor(time / 60)}`.padStart(2, 0)}:
                {`${time % 60}`.padStart(2, 0)}
              </strong>
              . {t("Réservez maintenant pour garantir votre location à ce tarif")}.
            </div>
          </div>
        </div>
        <hr />
        <div
          className="container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="models-div__coordonnees"
            style={{
              paddingTop: 5,
              paddingBottom: 5,
            }}
          >
            <div
              className="models-div__box"
              style={{
                minHeight: 420,
              }}
            >
              <div className="models-div__box__img">
                <div className="models-div__box__descr">
                  <div className="models-div__box__descr__name-price">
                    <div className="models-div__box__descr__name-price__name">
                      <p
                        style={{
                          fontSize: 18,
                        }}
                      >
                        {t("Quand / Où")}
                      </p>
                    </div>
                  </div>
                  <div
                    className=""
                    style={{
                      borderBottom: "none",
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 15,
                    }}
                  >
                    <span style={{ fontSize: 13 }}>
                      {pickDate} {pickTime}
                    </span>
                    <span style={{ textAlign: "right", fontSize: 13 }}>
                      {dropDate} {dropTime}
                    </span>
                  </div>
                  <div
                    style={{
                      borderBottom: "none",
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 15,
                    }}
                  >
                    <span style={{ fontSize: 13 }}>
                      {
                        dataPickupPoint.find(
                          (e) => e.id_pickup === parseInt(pickUp)
                        )?.lieu
                      }
                    </span>
                    <span style={{ textAlign: "right", fontSize: 13 }}>
                      {
                        dataPickupPoint.find(
                          (e) => e.id_pickup === parseInt(dropOff)
                        )?.lieu
                      }
                    </span>
                  </div>
                  <>
                    <div
                      className="models-div__box__descr__name-price"
                      style={{
                        marginTop: 15,
                      }}
                    >
                      <div className="models-div__box__descr__name-price__name">
                        <p
                          style={{
                            fontSize: 18,
                          }}
                        >
                          {t("Options supplémentaires")}
                        </p>
                      </div>
                    </div>
                    <div
                      className="models-div__box__descr__name-price__details"
                      style={{
                        borderBottom: "none",
                      }}
                    >
                      {conducteurSup && (
                        <>
                          <span
                            style={{
                              fontSize: 13,
                            }}
                          >
                            {t("Conducteur supplémentaire")}
                          </span>
                          <span style={{ textAlign: "right", fontSize: 13 }}>
                            50,00 MAD <br />
                            <span style={{ fontSize: 10 }}>
                              (x {days > 1 ? `${days} jours` : `${days} jour`})
                            </span>
                          </span>
                        </>
                      )}
                      {siegeEnfant > 0 && (
                        <>
                          <span
                            style={{
                              fontSize: 13,
                            }}
                          >
                            {siegeEnfant} x {t("Siège enfant")}
                          </span>

                          <span style={{ textAlign: "right", fontSize: 13 }}>
                            {siegeEnfant * 50.0} MAD <br />
                            <span style={{ fontSize: 10 }}>
                              (x {days > 1 ? `${days} jours` : `${days} jour`})
                            </span>
                          </span>
                        </>
                      )}
                      {rehausseur > 0 && (
                        <>
                          <span
                            style={{
                              fontSize: 13,
                            }}
                          >
                            {rehausseur} x {t("Rehausseur")}
                          </span>
                          <span style={{ textAlign: "right", fontSize: 13 }}>
                            {rehausseur * 50.0} MAD <br />
                            <span style={{ fontSize: 10 }}>
                              (x {days > 1 ? `${days} jours` : `${days} jour`})
                            </span>
                          </span>
                        </>
                      )}
                      {assurance && (
                        <>
                          <span
                            style={{
                              fontSize: 13,
                            }}
                          >
                            {t("Assurance")}
                          </span>
                          <span style={{ textAlign: "right", fontSize: 13 }}>
                            150.00 MAD <br />
                            <span style={{ fontSize: 10 }}>
                              (x {days > 1 ? `${days} ${t("jours")}` : `${days} ${t("jour")}`})
                            </span>
                          </span>
                        </>
                      )}
                    </div>
                  </>
                </div>
              </div>
            </div>

            <div
              className="models-div__box"
              style={{
                minHeight: 465,
              }}
            >
              <div className="models-div__box__img">
                <div className="models-div__box__descr">
                  <div className="models-div__box__descr__name-price">
                    <div className="models-div__box__descr__name-price__name">
                      <p
                        style={{
                          fontSize: 18,
                        }}
                      >
                        {t("Véhicule")}
                      </p>
                    </div>
                  </div>
                  <div className="models-div__box__descr__name-price__details">
                    <span
                      style={{
                        fontSize: 13,
                      }}
                    >
                      {dataCar.brand} ({dataCar.gearBox})
                    </span>
                    <span
                      style={{
                        textAlign: "right",
                        textAlign: "center ",
                        fontSize: 13,
                        fontWeight: "bold",
                      }}
                    >
                      {price}MAD(H.T)
                      <br />
                      {typePaiement === "cash"
                        ? price + price * 0.2
                        : price + price * 0.2 + price * 0.05}
                      MAD(T.T.C)
                    </span>
                  </div>
                  <div
                    style={{
                      height: 250,
                      width: "100%",
                      padding: 0,
                    }}
                  >
                    {/* <p>hhh</p> */}
                    <img
                      style={{
                        objectFit: "contain",
                        height: "100%",
                        width: "100%",
                      }}
                      src={dataCar.image}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />
        <div
          style={{
            marginTop: 15,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 7,
          }}
        >
          <p className="error-message" style={{ width: "50%" }}>
            {t("Tous les champs sont obligatoires")} !!{" "}
            <i>
              <FaCircleXmark />
            </i>
          </p>
          <div
            // onSubmit={confirmReservation}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "50%",
                marginBottom: 20,
                fontSize: 20,
                fontWeight: "revert",
              }}
            >
              <label htmlFor="civilité">{t("Civilité")} : *</label>
              <select
                id="civilité"
                style={{
                  padding: 10,
                  width: "100%",
                }}
                onChange={(e) => setCivilite(e.target.value)}
                required
              >
                <option value="">{t("Choisissez votre civilité")}</option>
                <option value="Homme">{t("Homme")}</option>
                <option value="Femme">{t("Femme")}</option>
              </select>
            </div>
            <div
              style={{
                width: "50%",
                marginBottom: 20,
                fontSize: 20,
                fontWeight: "revert",
              }}
            >
              <label htmlFor="Nom de famille">{t("Nom de famille")} : *</label>
              <input
                type="text"
                id="Nom de famille"
                placeholder={t("Nom de famille")}
                style={{
                  padding: 7,
                  width: "100%",
                }}
                onChange={(e) => setNomFamille(e.target.value)}
                required
              />
            </div>
            <div
              style={{
                width: "50%",
                marginBottom: 20,
                fontSize: 20,
                fontWeight: "revert",
              }}
            >
              <label htmlFor="Prénom">{t("Prénom")} : *</label>
              <input
                type="text"
                id="Prénom"
                placeholder={t("Prénom")}
                style={{
                  padding: 7,
                  width: "100%",
                }}
                onChange={(e) => setPrenom(e.target.value)}
                required
              />
            </div>
            <div
              style={{
                width: "50%",
                marginBottom: 20,
                fontSize: 20,
                fontWeight: "revert",
              }}
            >
              <label htmlFor="Adresse e-mail">{t("Adresse e-mail")} : *</label>
              <input
                type="email"
                id="Adresse e-mail"
                placeholder={t("Adresse e-mail")}
                style={{
                  padding: 7,
                  width: "100%",
                }}
                onChange={(e) => setAdresseEmail(e.target.value)}
                required
              />
            </div>
            <div
              style={{
                width: "50%",
                marginBottom: 20,
                fontSize: 20,
                fontWeight: "revert",
              }}
            >
              {confirmAdresseEmail &&
              adressEmail.toLowerCase() !==
                confirmAdresseEmail.toLowerCase() ? (
                <span style={{ color: "red" }}>
                  <strong>{t("Erreur")}! </strong>{t("Veuillez corriger votre adresse email")}.
                </span>
              ) : (
                <label htmlFor="Confirmez votre adresse e-mail">
                  {t("Confirmez votre adresse e-mail")} : *
                </label>
              )}
              <input
                type="email"
                id="Confirmez votre adresse e-mail"
                placeholder={t("Confirmez votre adresse e-mail")}
                style={{
                  padding: 7,
                  width: "100%",
                }}
                onChange={(e) => setConfirmAdresseEmail(e.target.value)}
                required
              />
            </div>
            <div
              style={{
                width: "50%",
                marginBottom: 20,
                fontSize: 20,
                fontWeight: "revert",
              }}
            >
              <label htmlFor="Numéro de téléphone portable">
                {t("Numéro de téléphone")} : *
              </label>
              <input
                type="text"
                id="Numéro de téléphone portable"
                placeholder={t("Numéro de téléphone")}
                style={{
                  padding: 7,
                  width: "100%",
                }}
                onChange={(e) => setNumTel(e.target.value)}
                required
              />
            </div>
            <div
              style={{
                width: "50%",
                marginBottom: 20,
                fontSize: 20,
                fontWeight: "revert",
              }}
            >
              <label htmlFor="Date de naissance">{t("Date de naissance")} : *</label>
              <input
                type="date"
                id="Date de naissance"
                style={{
                  padding: 7,
                  width: "100%",
                }}
                onChange={(e) => setDateNaissance(e.target.value)}
                max={`${maxYear}-12-31`}
                required
              />
            </div>
            <div
              style={{
                width: "50%",
                marginBottom: 20,
                fontSize: 20,
                fontWeight: "revert",
              }}
            >
              <label htmlFor="CIN / Passport">{t("CIN / Passeport")} : *</label>
              <input
                type="text"
                id="CIN / Passport"
                placeholder={t("CIN / Passeport")}
                style={{
                  padding: 7,
                  width: "100%",
                }}
                onChange={(e) => setCinOrPass(e.target.value)}
                required
              />
            </div>
            <div
              style={{
                marginTop: "15px",
                marginBottom: "15px",
                paddingTop: 10,
                borderTop: "1px solid gray",
                width: "50%",
                fontSize: 20,
                fontWeight: "revert",
              }}
            >
              <span>{t("Adresse")}</span>
            </div>

            <div
              style={{
                width: "50%",
                marginBottom: 20,
                fontSize: 20,
                fontWeight: "revert",
              }}
            >
              <label htmlFor="ville">{t("ville")} : *</label>
              <input
                type="text"
                id="ville"
                placeholder={t("ville")}
                style={{
                  padding: 7,
                  width: "100%",
                }}
                onChange={(e) => setVille(e.target.value)}
                required
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 5,
                justifyContent: "space-between",
                width: "50%",
              }}
            >
              <div
                style={{
                  width: "100%",
                  marginBottom: 20,
                  fontSize: 20,
                  fontWeight: "revert",
                }}
              >
                <label htmlFor="Code Postal">{t("Code Postal")} : *</label>
                <input
                  type="text"
                  id="Code Postal"
                  placeholder={t("Code Postal")}
                  style={{
                    padding: 7,
                    width: "100%",
                  }}
                  onChange={(e) => setCodePostal(e.target.value)}
                  required
                />
              </div>
              <div
                style={{
                  width: "100%",
                  marginBottom: 20,
                  fontSize: 20,
                  fontWeight: "revert",
                }}
              >
                <label htmlFor="Pays">{t("Pays")} : *</label>
                <select
                  id="Pays"
                  style={{
                    padding: 8,
                    width: "100%",
                  }}
                  onChange={(e) => setPays(e.target.value)}
                  required
                >
                  <>
                    <option value="">{t("Séléctionner votre pays")}</option>
                    <option value="Afghanistan">Afghanistan</option>
                    <option value="Afrique du Sud">Afrique du Sud</option>
                    <option value="Albanie">Albanie</option>
                    <option value="Algérie">Algérie</option>
                    <option value="Allemagne">Allemagne</option>
                    <option value="Andorre">Andorre</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Antigua-et-Barbuda">
                      Antigua-et-Barbuda
                    </option>
                    <option value="Arabie saoudite">Arabie saoudite</option>
                    <option value="Argentine">Argentine</option>
                    <option value="Arménie">Arménie</option>
                    <option value="Aruba">Aruba</option>
                    <option value="Australie">Australie</option>
                    <option value="Autriche">Autriche</option>
                    <option value="Azerbaïdjan">Azerbaïdjan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrein">Bahrein</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbade">Barbade</option>
                    <option value="Belgique">Belgique</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermudes">Bermudes</option>
                    <option value="Bhoutan">Bhoutan</option>
                    <option value="Biélorussie">Biélorussie</option>
                    <option value="Bolivie">Bolivie</option>
                    <option value="Bonaire">Bonaire</option>
                    <option value="Bosnie-Herzégovine">
                      Bosnie-Herzégovine
                    </option>
                    <option value="Botswana">Botswana</option>
                    <option value="Brésil">Brésil</option>
                    <option value="Brunei Darussalam">Brunei Darussalam</option>
                    <option value="Bulgarie">Bulgarie</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cambodge">Cambodge</option>
                    <option value="Cameroun">Cameroun</option>
                    <option value="Canada">Canada</option>
                    <option value="Cap-Vert">Cap-Vert</option>
                    <option value="Chili">Chili</option>
                    <option value="Chine">Chine</option>
                    <option value="Chypre">Chypre</option>
                    <option value="Cité du Vatican">Cité du Vatican</option>
                    <option value="Colombie">Colombie</option>
                    <option value="Comores">Comores</option>
                    <option value="Congo">Congo</option>
                    <option value="Congo D.R.">Congo D.R.</option>
                    <option value="Corée">Corée</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Côte d'Ivoire">Côte d'Ivoire</option>
                    <option value="Croatie">Croatie</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Curacao">Curacao</option>
                    <option value="Danemark">Danemark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominique">Dominique</option>
                    <option value="Égypte">Égypte</option>
                    <option value="Émirats arabes unis">
                      Émirats arabes unis
                    </option>
                    <option value="Équateur">Équateur</option>
                    <option value="Espagne">Espagne</option>
                    <option value="Estonie">Estonie</option>
                    <option value="eSwatini">eSwatini</option>
                    <option value="Etats-Unis">Etats-Unis</option>
                    <option value="Éthiopie">Éthiopie</option>
                    <option value="Fidji">Fidji</option>
                    <option value="Finlande">Finlande</option>
                    <option value="France">France</option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambie">Gambie</option>
                    <option value="Géorgie">Géorgie</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Grand Cayman">Grand Cayman</option>
                    <option value="Grèce">Grèce</option>
                    <option value="Greenland">Greenland</option>
                    <option value="Grenade">Grenade</option>
                    <option value="Guadeloupe">Guadeloupe</option>
                    <option value="Guam">Guam</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guernsey">Guernsey</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guinée">Guinée</option>
                    <option value="Guinée-Bissao">Guinée-Bissao</option>
                    <option value="Guinée équatoriale">
                      Guinée équatoriale
                    </option>
                    <option value="Guyane française">Guyane française</option>
                    <option value="Haïti">Haïti</option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hong Kong">Hong Kong</option>
                    <option value="Hongrie">Hongrie</option>
                    <option value="Ile Norfolk">Ile Norfolk</option>
                    <option value="Iles Cook">Iles Cook</option>
                    <option value="Iles Féroé">Iles Féroé</option>
                    <option value="Iles Marshall">Iles Marshall</option>
                    <option value="Iles Salomon">Iles Salomon</option>
                    <option value="Iles Turks-et-Caicos">
                      Iles Turks-et-Caicos
                    </option>
                    <option value="Iles Vierges américaines">
                      Iles Vierges américaines
                    </option>
                    <option value="Inde">Inde</option>
                    <option value="Indonésie">Indonésie</option>
                    <option value="Iran">Iran</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Irlande">Irlande</option>
                    <option value="Islande">Islande</option>
                    <option value="Isle Of Man">Isle Of Man</option>
                    <option value="Israël">Israël</option>
                    <option value="Italie">Italie</option>
                    <option value="Jamaïque">Jamaïque</option>
                    <option value="Japon">Japon</option>
                    <option value="Jersey">Jersey</option>
                    <option value="Jordanie">Jordanie</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kirghizistan">Kirghizistan</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Kosovo">Kosovo</option>
                    <option value="Koweit">Koweit</option>
                    <option value="Laos">Laos</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Lettonie">Lettonie</option>
                    <option value="Liban">Liban</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libye">Libye</option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lituanie">Lituanie</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macau">Macau</option>
                    <option value="Macédonie">Macédonie</option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malaisie">Malaisie</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malte">Malte</option>
                    <option value="Mariannes du Nord">Mariannes du Nord</option>
                    <option value="Maroc">Maroc</option>
                    <option value="Martinique">Martinique</option>
                    <option value="Maurice">Maurice</option>
                    <option value="Mauritanie">Mauritanie</option>
                    <option value="Mayotte">Mayotte</option>
                    <option value="Mexique">Mexique</option>
                    <option value="Micronésie">Micronésie</option>
                    <option value="Moldavie">Moldavie</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolie">Mongolie</option>
                    <option value="Montenegro">Montenegro</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Namibie">Namibie</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Népal">Népal</option>
                    <option value="Nicarague">Nicarague</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Norvège">Norvège</option>
                    <option value="Nouvelle-Calédonie">
                      Nouvelle-Calédonie
                    </option>
                    <option value="Nouvelle-Zélande">Nouvelle-Zélande</option>
                    <option value="Oman">Oman</option>
                    <option value="Ouganda">Ouganda</option>
                    <option value="Ouzbékistan">Ouzbékistan</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Panama">Panama</option>
                    <option value="Papouasie-Nouvelle-Guinée">
                      Papouasie-Nouvelle-Guinée
                    </option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Pays-Bas">Pays-Bas</option>
                    <option value="Perou">Perou</option>
                    <option value="Philippines">Philippines</option>
                    <option value="Pologne">Pologne</option>
                    <option value="Polynésie française">
                      Polynésie française
                    </option>
                    <option value="Porto Rico">Porto Rico</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Qatar">Qatar</option>
                    <option value="République centrafricaine">
                      République centrafricaine
                    </option>
                    <option value="République Dominicaine">
                      République Dominicaine
                    </option>
                    <option value="République Tchèque">
                      République Tchèque
                    </option>
                    <option value="Réunion">Réunion</option>
                    <option value="Roumanie">Roumanie</option>
                    <option value="Royaume-Uni">Royaume-Uni</option>
                    <option value="Russie">Russie</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="Saint-Christophe-et-Niévès">
                      Saint-Christophe-et-Niévès
                    </option>
                    <option value="Sainte-Lucie">Sainte-Lucie</option>
                    <option value="Saint Martin, France">
                      Saint Martin, France
                    </option>
                    <option value="Saint Martin, Pays-Bas">
                      Saint Martin, Pays-Bas
                    </option>
                    <option value="Saint-Vincent-et-les-Grenadines">
                      Saint-Vincent-et-les-Grenadines
                    </option>
                    <option value="Salvador">Salvador</option>
                    <option value="Samoa">Samoa</option>
                    <option value="Samoa américaines">Samoa américaines</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Sao Tomé-et-Principe">
                      Sao Tomé-et-Principe
                    </option>
                    <option value="Sénégal">Sénégal</option>
                    <option value="Serbia et Montenegro">
                      Serbia et Montenegro
                    </option>
                    <option value="Serbie">Serbie</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Singapour">Singapour</option>
                    <option value="Slovaquie">Slovaquie</option>
                    <option value="Slovénie">Slovénie</option>
                    <option value="Somalie">Somalie</option>
                    <option value="Soudan">Soudan</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="St Barthelemy">St Barthelemy</option>
                    <option value="Suède">Suède</option>
                    <option value="Suisse">Suisse</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Syrie">Syrie</option>
                    <option value="Taiwan">Taiwan</option>
                    <option value="Tanzanie">Tanzanie</option>
                    <option value="Tchad">Tchad</option>
                    <option value="Thailande">Thailande</option>
                    <option value="Togo">Togo</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Tortola">Tortola</option>
                    <option value="Trinité-et-Tobago">Trinité-et-Tobago</option>
                    <option value="Tunisie">Tunisie</option>
                    <option value="Turkménistan">Turkménistan</option>
                    <option value="Turquie">Turquie</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="Uruguay">Uruguay</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Viêt Nam">Viêt Nam</option>
                    <option value="Yémen">Yémen</option>
                    <option value="Zambie">Zambie</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                  </>
                </select>
              </div>
            </div>
            <div
              style={{
                marginTop: "15px",
                marginBottom: "15px",
                paddingTop: 10,
                borderTop: "1px solid gray",
                width: "50%",
                fontSize: 20,
                fontWeight: "revert",
              }}
            >
              <span>{t("Paiement")}</span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "50%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 7,
                  cursor: "pointer",
                }}
              >
                <input
                  type="radio"
                  name="paiement"
                  id="cards"
                  disabled
                  value="cards"
                  style={{
                    height: 20,
                    width: 20,
                    verticalAlign: "middle",
                  }}
                  onChange={(e) => setTypePaiement(e.target.value)}
                  required
                />
                <label
                  htmlFor="cards"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 7,
                    cursor: "pointer",
                  }}
                >
                  <FaCcVisa size={55} />
                  <FaCcMastercard size={55} />
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 7,
                  cursor: "pointer",
                }}
              >
                <input
                  type="radio"
                  name="paiement"
                  value="cash"
                  id="position"
                  checked
                  style={{
                    height: 20,
                    width: 20,
                    verticalAlign: "middle",
                  }}
                  onChange={(e) => setTypePaiement(e.target.value)}
                  required
                />
                <label
                  htmlFor="position"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 7,
                    fontSize: 20,
                    cursor: "pointer",
                  }}
                >
                  <GiReceiveMoney size={45} /> {t("Sur place")}
                </label>
              </div>
            </div>

            {typePaiement === "cards" && (
              <>
                <div
                  style={{
                    marginTop: 10,
                    fontSize: 13,
                    border: "1px solid red",
                    padding: "7px 15px",
                    display: "flex",
                    alignItems: "center",
                    gap: 5,
                    background: "#ffeded",
                    borderRadius: 7,
                  }}
                >
                  <div>
                    <GoAlert size={25} color="red" />
                  </div>
                  <div>
                    {t("Merci de noter qu'une taxe de 5% sera ajoutée pour les paiements par carte")}.
                  </div>
                </div>
              </>
            )}

            <div
              style={{
                width: "50%",
                marginBottom: 20,
                fontSize: 13,
                marginTop: 25,
              }}
            >
              <span style={{ color: "red" }}>INFORMATIONS IMPORTANTES : </span>
              <br />
              <br />
              {/* <p>
                {t("Veuillez sélectionner la carte de paiement que vous souhaitez utiliser. Cette carte doit être au nom du conducteur principal car elle sera vérifiée au comptoir de location. Bien que les paiements en ligne avec une carte prépayée soient acceptés, veuillez noter qu’il vous faudra également présenter une carte bancaire non prépayée au moment du retrait")}.
              </p> */}

              <p>
                {t("La fonctionnalité de paiement en ligne sera bientôt disponible sur notre site. Cependant, le paiement sur place reste disponible")}.
              </p>
            </div>

            <div
              style={{
                marginTop: 20,
                marginBottom: 35,
              }}
            >
              <button
                className="confirmReservation"
                onClick={confirmReservation}
                style={{
                  transition: "0.4s ease",
                  padding: "10px 25px",
                  borderRadius: "10px",
                  border: "1px solid #eb5151",
                  fontSize: 18,
                  textDecoration: "none",
                  background: "#eb5151",
                  cursor: "pointer",
                  textTransform: "uppercase",
                  color: "white",
                }}
                disabled={modal || modalConfirmed}
              >
                {t("Confirmer votre réservation")}
              </button>
            </div>
          </div>
        </div>
      </>
      <Footer />

      {/* modal ------------------------------------ */}

      <>
        <div
          className={`booking-modal ${modal ? "active-modal" : ""}`}
          style={{
            height: "50%",
            background: "#000000c4",
            overflow: "hidden",
            borderRadius: 15,
          }}
        >
          <div className="booking-modal__title">
            <h2>{t("Expiration de session")}</h2>
          </div>
          <div
            style={{
              height: "90%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                fontSize: 18,
                padding: "7px 15px",
                display: "flex",
                alignItems: "center",
                gap: 5,
                color: "white",
              }}
            >
              <div>
                <MdTimerOff size={55} color="red" />
              </div>
              <div>
                {t("La session a pris fin. Vous pouvez recommencer la procédure dès maintenant")}.
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: 10,
              marginRight: 10,
            }}
          >
            <button
              className="confirmReservation"
              onClick={() => {
                window.location.href = "/";
              }}
              style={{
                transition: "0.4s ease",
                padding: "10px 25px",
                borderRadius: "10px",
                border: "1px solid #eb5151",
                fontSize: 13,
                textDecoration: "none",
                background: "#eb5151",
                cursor: "pointer",
                textTransform: "uppercase",
                color: "white",
                width: "40%",
              }}
            >
              {t("Retour à la page d'accueil")}
            </button>
          </div>
        </div>
      </>

      <div
        className={`booking-modal ${modalConfirmed ? "active-modal" : ""}`}
        style={{
          height: "50%",
          background: "white",
          overflow: "hidden",
          borderRadius: 15,
        }}
      >
        {/* title */}
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 15,
          }}
        >
          <div
            style={{
              fontSize: 18,
              padding: "7px 15px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 5,
              color: "black",
            }}
          >
            <div
              style={{
                marginBottom: 15,
              }}
            >
              <img src={imgCheck} height={100} width={100} />
            </div>
            <div
              style={{
                fontFamily: "Lucida Console, Courier New, monospace",
                marginBottom: 15,
              }}
            >
              {t("Votre réservation est confirmée, un email de confirmation vous sera envoyé")}
            </div>
            <p
              style={{
                color: "gray",
                marginBottom: 15,
              }}
            >
              {t("Merci de nous avoir choisi")}
            </p>
            <button
              className="confirmReservation"
              onClick={() => {
                window.location.href = "/";
              }}
              style={{
                transition: "0.4s ease",
                padding: "10px 25px",
                borderRadius: "10px",
                border: "1px solid #eb5151",
                fontSize: 13,
                textDecoration: "none",
                background: "#eb5151",
                cursor: "pointer",
                textTransform: "uppercase",
                color: "white",
              }}
            >
              {t("page d'accueil")}
            </button>
          </div>
        </div>
      </div>

      <div
        style={{
          height: "10%",
          background: "red",
        }}
      ></div>
    </section>
  );
}

export default Coordonnees;