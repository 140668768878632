import { useState } from "react";
import { useTranslation } from "react-i18next";

function Faq() {
  const { t } = useTranslation();
  const [activeQ, setActiveQ] = useState("q1");

  const openQ = (id) => {
    setActiveQ(activeQ === id ? "" : id);
  };

  const getClassAnswer = (id) => {
    return activeQ === id ? "active-answer" : "";
  };

  const getClassQuestion = (id) => {
    return activeQ === id ? "active-question" : "";
  };

  return (
    <>
      <section className="faq-section">
        <div className="container">
          <div className="faq-content">
            <div className="faq-content__title">
              <h5>FAQ</h5>
              <h2>{t("Questions fréquemment posées")}</h2>
              <p>
                {t("Questions fréquemment posées sur le processus de réservation d'une voiture de location sur notre site web : Réponses aux préoccupations et aux demandes les plus courantes")}.
              </p>
            </div>

            <div className="all-questions">
              <div className="faq-box">
                <div
                  id="q1"
                  onClick={() => openQ("q1")}
                  className={`faq-box__question  ${getClassQuestion("q1")}`}
                >
                  <p>
                    1. {t("De quels document ai-je besoin pour louer une voiture de location")} ?
                  </p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q1"
                  onClick={() => openQ("q1")}
                  className={`faq-box__answer ${getClassAnswer("q1")}`}
                >
                  {t("Pour la prise en charge de votre voiture de location au Maroc vous devrez vous présenter avec les documents suivants : -Pièce d'identité valide (passeport ou carte d'identité) -Permis de conduire en cours de validité du titulaire du contract et de chaque conducteur additionnel -Attention, seuls les documents originaux sont acceptés pour louer une voiture")}.
                </div>
              </div>
              <div className="faq-box">
                <div
                  id="q2"
                  onClick={() => openQ("q2")}
                  className={`faq-box__question ${getClassQuestion("q2")}`}
                >
                  <p>
                    2. {t("Quel âge minimum faut-il avoir pour louer une voiture")} ?
                  </p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q2"
                  onClick={() => openQ("q2")}
                  className={`faq-box__answer ${getClassAnswer("q2")}`}
                >
                  {t("Pour louer une voiture, le conducteur doit avoir au moins 21 ans et 24 mois d'ancienneté de permis de conduire")}.
                </div>
              </div>
              <div className="faq-box">
                <div
                  id="q3"
                  onClick={() => openQ("q3")}
                  className={`faq-box__question ${getClassQuestion("q3")}`}
                >
                  <p>
                    3. {t("L’annulation d'une réservation génère-t-elle des frais")} ?
                  </p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q3"
                  onClick={() => openQ("q3")}
                  className={`faq-box__answer ${getClassAnswer("q3")}`}
                >
                  {t("L'annulation de votre réservation est gratuite jusqu'à 48  heures avant votre arrivée et vous donne droit à un remboursement complet du montant, si votre annulation est faite 24 heures avant votre arrivée, vous devrez payer 30% du montant total, départ anticipé, vous devrez payer 50% du montant total")}.
                </div>
              </div>
              <div className="faq-box">
                <div
                  id="q4"
                  onClick={() => openQ("q4")}
                  className={`faq-box__question ${getClassQuestion("q4")}`}
                >
                  <p>
                    3. {t("J’ai un permis de conduire étranger, puis-je louer une voiture")} ?
                  </p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q4"
                  onClick={() => openQ("q4")}
                  className={`faq-box__answer ${getClassAnswer("q4")}`}
                >
                  {t("Oui vous pouvez louer une voiture avec votre permis de conduire étranger, à une seule condition, votre permis de conduire doit être valide depuis au moins deux ans")}.
                </div>
              </div>
              <div className="faq-box">
                <div
                  id="q5"
                  onClick={() => openQ("q5")}
                  className={`faq-box__question ${getClassQuestion("q5")}`}
                >
                  <p>
                    3. {t("Quelle est ma responsabilité en cas de dommage du véhicule loué")} ?
                  </p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q5"
                  onClick={() => openQ("q5")}
                  className={`faq-box__answer ${getClassAnswer("q5")}`}
                >
                  {t("Votre responsabilité en cas de dommage varie selon le type de véhicules loués, lors de la réservation en ligne vous serez amenée à choisir une assurance complémentaire pour une  protection maximale qui annule la somme à payer en cas d'accident sinon vous serez amené à rembourser un dédommagement qui varie selon la gravité")}.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Faq;
