import { useState } from "react";
import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import Testimonials from "../components/Testimonials";
import axiosClient from "../axios-client";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function TestimonialsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");

  const addComment = () => {
    const formData = new FormData();
    formData.append("first_name", nom);
    formData.append("last_name", prenom);
    formData.append("email", email);
    formData.append("comment", comment);
    axiosClient.post("/reviews/", formData);
  };

  return (
    <>
      <section className="testimonial-page">
        <HeroPages name={t("Témoignages")} />
        <Testimonials />
        <div
          style={{
            marginTop: 35,
          }}
        >
          <div className="container">
            <div className="contact-div">
              <div className="contact-div__text">
                <h3
                  style={{
                    fontSize: 24,
                  }}
                >
                  {t("Partagez votre avis pour une expérience toujours meilleure")} !
                </h3>
                <p>
                  {t("Laissez-nous votre commentaire pour nous aider à améliorer nos services. Vos retours sont importants pour nous, que ce soit une suggestion, un compliment ou une préoccupation. Merci de partager votre expérience et contribuer à notre amélioration continue")}.
                </p>
              </div>
              <div className="contact-div__form">
                <form>
                  <label>
                    {t("Nom")} <b>*</b>
                  </label>
                  <input
                    type="text"
                    placeholder={t("Nom")}
                    onChange={(e) => setNom(e.target.value)}
                  ></input>

                  <label>
                    {t("Prénom")} <b>*</b>
                  </label>
                  <input
                    type="text"
                    placeholder={t("Prénom")}
                    onChange={(e) => setPrenom(e.target.value)}
                  ></input>

                  <label>
                    Email <b>*</b>
                  </label>
                  <input
                    type="email"
                    placeholder="youremail@example.com"
                    onChange={(e) => setEmail(e.target.value)}
                  ></input>

                  <label>
                    {t("Parlez-nous en")} <b>*</b>
                  </label>
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                    }}
                  >
                    <textarea
                      placeholder={t("Ecrire ici..")}
                      onChange={(e) => setComment(e.target.value)}
                      maxLength={255}
                      style={{
                        width: "100%",
                      }}
                    ></textarea>
                    <span
                      style={{
                        position: "absolute",
                        right:6,
                        top:2
                      }}
                    >
                      {comment.length}/255
                    </span>
                  </div>

                  <button onClick={addComment}>
                    <i className="fa-solid fa-envelope-open-text"></i>&nbsp;
                    {t("Envoyer")}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default TestimonialsPage;
