import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  return (
    <>
    <hr/>
      <footer>
        <div className="container">
          <div className="footer-content">
            <ul className="footer-content__1">
              <li>
                <span>Australie</span> Cars
              </li>
              <li>
                {t("Nous offrons une large gamme de véhicules pour tous vos besoins de conduite. Nous avons la voiture idéale pour répondre à vos besoins")}.
              </li>
              <li>
                <p>
                  <i className="fa-solid fa-phone"></i> &nbsp; (+212) -662-024-423
                </p>
              </li>

              <li>
                <p
                >
                  <i className="fa-solid fa-envelope"></i>
                  &nbsp; contact@australiecars.com
                </p>
              </li>

            </ul>

            <ul className="footer-content__2">
              <li>{t("Heures d'ouverture")}</li>
              <li>Lun - Dim : 9:00AM - 7:30PM</li>
            </ul>

            <ul className="footer-content__2">
              <li>{t("Abonnement")}</li>
              <li>
                <p>
                  {t("Inscrivez votre adresse e-mail pour recevoir les dernières nouvelles et mises à jour")}.
                </p>
              </li>
              <li>
                <input type="email" placeholder={t("Entrer votre adresse mail")}></input>
              </li>
              <li>
                <button className="submit-email">{t("Envoyer")}</button>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
