import Iframe from "react-iframe";

function Download() {
  return (
    <>
      <div style={{ height: "100vh", width: "100%" }}>
        <Iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3440.4274411012166!2d-9.587972925822015!3d30.423982600491204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xdb3b7c42788e189%3A0x428266aa0326192a!2sAustralie%20Cars!5e0!3m2!1sfr!2sma!4v1702932904604!5m2!1sfr!2sma"
          width="100%"
          height="100%"
          style="border:0;"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        />
      </div>
    </>
  );
}

export default Download;
