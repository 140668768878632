import { useEffect, useState } from "react";
import CarAudi from "../images/cars-big/audia1.jpg";
import CarGolf from "../images/cars-big/golf6.jpg";
import CarToyota from "../images/cars-big/toyotacamry.jpg";
import CarBmw from "../images/cars-big/bmw320.jpg";
import CarMercedes from "../images/cars-big/benz.jpg";
import CarPassat from "../images/cars-big/passatcc.jpg";
import { FaCircleXmark } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import axiosClient from "../axios-client";
import { useTranslation } from "react-i18next";

function BookCar() {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false); //  class - active-modal

  const today = new Date().toISOString().split("T")[0]; // Get today's date in "YYYY-MM-DD" format

  const getDefaultEndDate = (start) => {
    const nextDay = new Date(start);
    nextDay.setDate(nextDay.getDate() + 1);
    return nextDay.toISOString().split("T")[0];
  };

  // booking car
  const [carType, setCarType] = useState("");
  const [pickUp, setPickUp] = useState("");
  const [dropOff, setDropOff] = useState("");
  const [pickDate, setPickDate] = useState(today);
  const [pickTime, setPickTime] = useState("");
  const [dropDate, setDropDate] = useState("");
  const [dropTime, setDropTime] = useState("");
  const [carImg, setCarImg] = useState("");

  // modal infos
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipCode] = useState("");

  const navigate = useNavigate();

  // taking value of modal inputs
  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleLastName = (e) => {
    setLastName(e.target.value);
  };

  const handlePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleAge = (e) => {
    setAge(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleAddress = (e) => {
    setAddress(e.target.value);
  };

  const handleCity = (e) => {
    setCity(e.target.value);
  };

  const handleZip = (e) => {
    setZipCode(e.target.value);
  };

  // disable page scroll when modal is displayed
  useEffect(() => {
    if (modal === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modal]);

  // confirm modal booking
  const confirmBooking = (e) => {
    e.preventDefault();
    setModal(!modal);
    const doneMsg = document.querySelector(".booking-done");
    doneMsg.style.display = "flex";
  };

  // taking value of booking inputs
  const handleCar = (e) => {
    setCarType(e.target.value);
    setCarImg(e.target.value);
  };

  const handlePick = (e) => {
    setPickUp(e.target.value);
  };

  const handleDrop = (e) => {
    setDropOff(e.target.value);
  };

  const handlePickDate = (e) => {
    setPickDate(e.target.value);
  };

  const handlePickTime = (e) => {
    setPickTime(e.target.value);
  };

  const handleDropDate = (e) => {
    setDropDate(e.target.value);
  };

  const handleDropTime = (e) => {
    setDropTime(e.target.value);
  };

  // based on value name show car img
  let imgUrl;
  switch (carImg) {
    case "Audi A1 S-Line":
      imgUrl = CarAudi;
      break;
    case "VW Golf 6":
      imgUrl = CarGolf;
      break;
    case "Toyota Camry":
      imgUrl = CarToyota;
      break;
    case "BMW 320 ModernLine":
      imgUrl = CarBmw;
      break;
    case "Mercedes-Benz GLK":
      imgUrl = CarMercedes;
      break;
    case "VW Passat CC":
      imgUrl = CarPassat;
      break;
    default:
      imgUrl = "";
  }

  // hide message
  const hideMessage = () => {
    const doneMsg = document.querySelector(".booking-done");
    doneMsg.style.display = "none";
  };

  // open modal when all inputs are fulfilled
  const openModal = (e) => {
    e.preventDefault();
    const errorMsg = document.querySelector(".error-message");
    if (
      pickUp === "" ||
      dropOff === "" ||
      pickDate === "" ||
      pickTime === "" ||
      dropDate === "" ||
      dropTime === ""
    ) {
      errorMsg.style.display = "flex";
    } else {
      navigate("/search", {
        state: {
          pickUp,
          dropOff,
          pickDate,
          pickTime,
          dropDate,
          dropTime,
        },
      });
      // setModal(!modal);
      // const modalDiv = document.querySelector(".booking-modal");
      // modalDiv.scroll(0, 0);
      // errorMsg.style.display = "none";
    }
  };

  const [dataPickup_Point, setPickup_Point] = useState([]);
  useEffect(() => {
    axiosClient.get("/pickup-point/").then((res) => {
      setPickup_Point(res.data);
    });
  }, []);

  console.log();

  return (
    <>
      <section id="booking-section" className="book-section">
        {/* overlay */}
        <div
          onClick={openModal}
          className={`modal-overlay ${modal ? "active-modal" : ""}`}
        ></div>

        <div className="container">
          <div className="book-content">
            <div className="book-content__box">
              <h2>{t("Réserver une voiture")}</h2>

              <p className="error-message">
              {t("Tous les champs sont obligatoires")} !!{" "}
                <i>
                  <FaCircleXmark />
                </i>
              </p>

              <p className="booking-done">
              {t("Vérifiez votre courrier électronique pour confirmer une commande.")}{" "}
                <i onClick={hideMessage} className="fa-solid fa-xmark"></i>
              </p>

              <form className="box-form">
                <div
                  className="box-form__car-type"
                  style={{
                    position: "relative",
                    zIndex: 1,
                    background: "none",
                  }}
                >
                  <label>
                    <i className="fa-solid fa-location-dot"></i> &nbsp; {t("Lieu de départ")} :<b>*</b>
                  </label>
                  <select value={pickUp} onChange={handlePick}>
                    <option value="">{t("Sélectionnez votre choix")}</option>
                    {dataPickup_Point.map((val, key) => {
                      return (
                        <>
                          <option selected key={key} value={val.id_pickup}>
                            {val.lieu}
                          </option>
                        </>
                      );
                    })}
                  </select>
                  {pickUp && (
                    <span
                      style={{
                        position: "absolute",
                        top: "60%",
                        bottom: "40%",
                        right: 30,
                        color: "#ababab",
                        fontSize: 12,
                        zIndex: 0,
                      }}
                    >
                      (+
                      {
                        dataPickup_Point.find(
                          (e) => e.id_pickup === parseInt(pickUp)
                        )?.tarif
                      }
                      MAD)
                    </span>
                  )}
                  <span
                    style={{
                      position: "absolute",
                      // top: "60%",
                      bottom: -20,
                      left: 0,
                      color: "#F78D7C",
                      fontSize: 12,
                      marginTop: "8px",
                    }}
                  >
                    * (+ {t("Prix de transfert")})
                  </span>
                </div>

                <div
                  className="box-form__car-type"
                  style={{ position: "relative" }}
                >
                  <label>
                    <i className="fa-solid fa-location-dot"></i> &nbsp; {t("Lieu de retour")} : <b>*</b>
                  </label>
                  <select value={dropOff} onChange={handleDrop}>
                    <option value="">{t("Sélectionnez votre choix")}</option>
                    {dataPickup_Point.map((val, key) => {
                      return (
                        <option key={key} value={val.id_pickup}>
                          {val.lieu}
                        </option>
                      );
                    })}
                  </select>
                  {dropOff && (
                    <span
                      style={{
                        position: "absolute",
                        top: "60%",
                        bottom: "40%",
                        right: 30,
                        color: "#ababab",
                        fontSize: 12,
                      }}
                    >
                      (+
                      {
                        dataPickup_Point.find(
                          (e) => e.id_pickup === parseInt(dropOff)
                        )?.tarif
                      }
                      MAD)
                    </span>
                  )}
                  <span style={{
                        position: "absolute",
                        // top: "60%",
                        bottom: -20,
                        left: 0,
                        color: "#F78D7C",
                        fontSize: 12,
                        marginTop: "8px",
                      }}>
                  * (+ {t("Prix de transfert")})
                  </span>
                </div>

                <div className="box-form__car-time">
                  <div style={{ display: "flex", gap: 5 }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <label htmlFor="pickDate">
                        <i className="fa-regular fa-calendar-days "></i> &nbsp;
                        {t("Date de départ")} : <b>*</b>
                      </label>
                      <input
                        id="pickDate"
                        value={pickDate}
                        onChange={handlePickDate}
                        type="date"
                        min={today}
                      ></input>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label htmlFor="pickTime">
                        <i className="fa-regular fa-calendar-days "></i> &nbsp;
                        {t("Heure")} : <b>*</b>
                      </label>
                      <input
                        id="pickTime"
                        type="time"
                        value={pickTime}
                        onChange={handlePickTime}
                      ></input>
                    </div>
                  </div>
                </div>

                <div className="box-form__car-time">
                  <div style={{ display: "flex", gap: 5 }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <label htmlFor="droptime">
                        <i className="fa-regular fa-calendar-days "></i> &nbsp;
                        {t("Date de retour")} : <b>*</b>
                      </label>
                      <input
                        id="droptime"
                        value={
                          dropDate <= pickDate
                            ? setDropDate(getDefaultEndDate(pickDate))
                            : dropDate
                        }
                        onChange={handleDropDate}
                        type="date"
                        min={getDefaultEndDate(pickDate)}
                      ></input>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label htmlFor="dropTime">
                        <i className="fa-regular fa-calendar-days "></i> &nbsp;
                        {t("Heure")} : <b>*</b>
                      </label>
                      <input
                        id="dropTime"
                        type="time"
                        value={dropTime}
                        onChange={handleDropTime}
                      ></input>
                    </div>
                  </div>
                </div>

                {/* <div className="box-form__car-time">
                  <label htmlFor="droptime">
                    <i className="fa-regular fa-calendar-days "></i> &nbsp;
                    Drop-of
                    <b>*</b>
                  </label>
                  <input
                    id="droptime"
                    value={
                      dropTime <= pickTime
                        ? setDropTime(getDefaultEndDate(pickTime))
                        : dropTime
                    }
                    onChange={handleDropTime}
                    type="datetime-local"
                    min={getDefaultEndDate(pickTime)}
                  ></input>
                </div> */}

                <button onClick={openModal} type="submit">
                {t("Réserver ")}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* modal ------------------------------------ */}

      <div className={`booking-modal ${modal ? "active-modal" : ""}`}>
        {/* title */}
        <div className="booking-modal__title">
          <h2>{t("Compléter la réservation")}</h2>
          <i onClick={openModal} className="fa-solid fa-xmark"></i>
        </div>
        {/* message */}
        <div className="booking-modal__message">
          <h4>
            <i className="fa-solid fa-circle-info"></i> {t("En complétant cette demande de réservation, vous recevrez")} :
          </h4>
          <p>
            {t("un email de confirmation avec vos informations enregistrer")}.
          </p>
        </div>
        {/* car info */}
        <div className="booking-modal__car-info">
          <div className="dates-div">
            <div className="booking-modal__car-info__dates">
              <h5>{t("Lieu et date")}</h5>
              <span>
                <i className="fa-solid fa-location-dot"></i>
                <div>
                  <h6>{t("Date et heure de départ")}</h6>
                  <p>
                    {pickTime} /{" "}
                    <input type="time" className="input-time"></input>
                  </p>
                </div>
              </span>
            </div>

            <div className="booking-modal__car-info__dates">
              <span>
                <i className="fa-solid fa-location-dot"></i>
                <div>
                  <h6>{t("Date et heure de retour")}</h6>
                  <p>
                    {dropTime} /{" "}
                    <input type="time" className="input-time"></input>
                  </p>
                </div>
              </span>
            </div>

            <div className="booking-modal__car-info__dates">
              <span>
                <i className="fa-solid fa-calendar-days"></i>
                <div>
                  <h6>{t("Lieu de départ")}</h6>
                  <p>{pickUp}</p>
                </div>
              </span>
            </div>

            <div className="booking-modal__car-info__dates">
              <span>
                <i className="fa-solid fa-calendar-days"></i>
                <div>
                  <h6>{t("Lieu de retour")}</h6>
                  <p>{dropOff}</p>
                </div>
              </span>
            </div>
          </div>
          <div className="booking-modal__car-info__model">
            <h5>
              <span>Car -</span> {carType}
            </h5>
            {imgUrl && <img src={imgUrl} alt="car_img" />}
          </div>
        </div>
        {/* personal info */}
        <div className="booking-modal__person-info">
          <h4>Informations personnelles</h4>
          <form className="info-form">
            <div className="info-form__2col">
              <span>
                <label>
                  Prénom <b>*</b>
                </label>
                <input
                  value={name}
                  onChange={handleName}
                  type="text"
                  placeholder="Enter your first name"
                ></input>
                <p className="error-modal">Ce champ est obligatoire.</p>
              </span>

              <span>
                <label>
                  Nom <b>*</b>
                </label>
                <input
                  value={lastName}
                  onChange={handleLastName}
                  type="text"
                  placeholder="Enter your last name"
                ></input>
                <p className="error-modal ">Ce champ est obligatoire.</p>
              </span>

              <span>
                <label>
                  Telephone <b>*</b>
                </label>
                <input
                  value={phone}
                  onChange={handlePhone}
                  type="tel"
                  placeholder="Enter your phone number"
                ></input>
                <p className="error-modal">Ce champ est obligatoire.</p>
              </span>

              <span>
                <label>
                  Age <b>*</b>
                </label>
                <input
                  value={age}
                  onChange={handleAge}
                  type="number"
                  placeholder="18"
                ></input>
                <p className="error-modal ">Ce champ est obligatoire.</p>
              </span>
            </div>

            <div className="info-form__1col">
              <span>
                <label>
                  Email <b>*</b>
                </label>
                <input
                  value={email}
                  onChange={handleEmail}
                  type="email"
                  placeholder="Enter your email address"
                ></input>
                <p className="error-modal">Ce champ est obligatoire.</p>
              </span>

              <span>
                <label>
                  Address <b>*</b>
                </label>
                <input
                  value={address}
                  onChange={handleAddress}
                  type="text"
                  placeholder="Enter your street address"
                ></input>
                <p className="error-modal ">Ce champ est obligatoire.</p>
              </span>
            </div>

            <div className="info-form__2col">
              <span>
                <label>
                  Ville <b>*</b>
                </label>
                <input
                  value={city}
                  onChange={handleCity}
                  type="text"
                  placeholder="Enter your city"
                ></input>
                <p className="error-modal">Ce champ est obligatoire.</p>
              </span>
              <span>
                <label>
                  Code Postal <b>*</b>
                </label>
                <input
                  value={zipcode}
                  onChange={handleZip}
                  type="text"
                  placeholder="Enter your zip code"
                ></input>
                <p className="error-modal ">Ce champ est obligatoire.</p>
              </span>
            </div>

            <span className="info-form__checkbox">
              <input type="checkbox"></input>
              <p>
                S'il vous plaît envoyez-moi les dernières nouvelles et offres
              </p>
            </span>

            <div className="reserve-button">
              <button onClick={confirmBooking}>Réservez maintenant</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default BookCar;
