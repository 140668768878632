import { Link } from "react-router-dom";
import Logo from "../images/logo/logo.png";
import { useState } from "react";
import { LiaTimesSolid } from "react-icons/lia";
import { BiMenuAltRight } from "react-icons/bi";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import LanguageChanger from "./language/LanguageChanger";

function Navbar() {
  const { t } = useTranslation();
  const [nav, setNav] = useState(false);

  const openNav = () => {
    setNav(!nav);
  };

  useEffect(() => {
    if (nav) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [nav]);

  return (
    <>
      <nav>
        {/* mobile */}
        <div
          className={`mobile-navbar ${nav ? "open-nav" : ""}`}
          style={{ overflow: "scroll" }}
        >
          <div onClick={openNav} className="mobile-navbar__close">
            <LiaTimesSolid />
          </div>
          <ul className="mobile-navbar__links">
            <li>
              <Link onClick={openNav} to="/">
                {t("Accueil")}
              </Link>
            </li>
            <li>
              <Link onClick={openNav} to="/about">
                {t("À propos")}
              </Link>
            </li>
            <li>
              <Link onClick={openNav} to="/models">
                {t("Voitures")}
              </Link>
            </li>
            <li>
              <Link onClick={openNav} to="/testimonials">
                {t("Témoignages")}
              </Link>
            </li>
            <li>
              <Link onClick={openNav} to="/contact">
                {t("Contact")}
              </Link>
            </li>
          </ul>
        </div>

        {/* desktop */}

        <div className="navbar">
          <div className="navbar__img">
            <Link to="/" onClick={() => window.scrollTo(0, 0)}>
              <img src={Logo} alt="logo-img" />
            </Link>
          </div>
          <ul className="navbar__links">
            <li>
              <Link className="home-link" to="/">
                {t("Accueil")}
              </Link>
            </li>
            <li>
              {" "}
              <Link className="about-link" to="/about">
                {t("À propos")}
              </Link>
            </li>
            <li>
              {" "}
              <Link className="models-link" to="/models">
                {t("Voitures")}
              </Link>
            </li>
            <li>
              {" "}
              <Link className="testi-link" to="/testimonials">
                {t("Témoignages")}
              </Link>
            </li>
            <li>
              {" "}
              <Link className="contact-link" to="/contact">
                {t("Contact")}
              </Link>
            </li>
          </ul>
          <LanguageChanger />
          {/* mobile */}
          <div className="mobile-hamb" onClick={openNav}>
            <span>
              <BiMenuAltRight />
            </span>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
