import { useTranslation } from "react-i18next";
import SelectCar from "../images/plan/icon1.png";
import Contact from "../images/plan/icon2.png";
import Drive from "../images/plan/icon3.png";

function PlanTrip() {
  const { t } = useTranslation();
  return (
    <>
      <section className="plan-section">
        <div className="container">
          <div className="plan-container">
            <div className="plan-container__title">
              <h3>{t("Planifiez votre voyage maintenant")}</h3>
              <h2>{t("Location de voiture rapide et facile")}</h2>
            </div>

            <div className="plan-container__boxes">
              <div className="plan-container__boxes__box">
                <img src={SelectCar} alt="icon_img" />
                <h3 style={{textAlign:'left'}}>{t("Choisissez une voiture")}</h3>
                <p style={{textAlign:'left'}}>
                  {t("Nous proposons une large gamme de véhicules pour tous vos besoins de conduite. Nous avons la voiture parfaite pour répondre à vos besoins")}.
                </p>
              </div>

              <div className="plan-container__boxes__box">
                <img src={Contact} alt="icon_img" />
                <h3 style={{textAlign:'left'}}>{t("Contacter l'opérateur")}</h3>
                <p style={{textAlign:'left'}}>
                  {t("Nos opérateurs qualifiés et sympathiques se tiennent constamment disponibles pour répondre à la moindre de vos questions ou préoccupations")}.
                </p>
              </div>

              <div className="plan-container__boxes__box">
                <img src={Drive} alt="icon_img" />
                <h3 style={{textAlign:'left'}}>{t("Prenez le volant")}</h3>
                <p style={{textAlign:'left'}}>
                  {t("Choisissez entre un paiement facile sur place à votre arrivée ou profitez de l'avantage de réserver en ligne en toute simplicité")}.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PlanTrip;
