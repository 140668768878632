import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import CarImg1 from "../images/cars-big/audi-box.png";
import CarImg2 from "../images/cars-big/golf6-box.png";
import CarImg3 from "../images/cars-big/toyota-box.png";
import CarImg4 from "../images/cars-big/bmw-box.png";
import CarImg5 from "../images/cars-big/benz-box.png";
import CarImg6 from "../images/cars-big/passat-box.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TbListDetails } from "react-icons/tb";
import { useEffect, useState } from "react";
import { LiaTimesSolid } from "react-icons/lia";
import { FaCheck } from "react-icons/fa6";
import { FaInfoCircle } from "react-icons/fa";
import { GrStatusWarning } from "react-icons/gr";
import axiosClient from "../axios-client";
import { useTranslation } from "react-i18next";

function SearchCar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [pickUp, dropOff, pickDate, pickTime, dropDate, dropTime] = [
    location.state.pickUp,
    location.state.dropOff,
    location.state.pickDate,
    location.state.pickTime,
    location.state.dropDate,
    location.state.dropTime,
  ];

  const [dataTarifs, setDataTarifs] = useState([]);
  const [pricePickUp, setPricePickUp] = useState();
  useEffect(() => {
    
    console.log('location state : ',location.state.length);

    axiosClient.get("/tarifs/").then((res) => {
      setDataTarifs(res.data);
    });

    axiosClient.get("/pickup-point/").then((res) => {
      setPricePickUp(res.data);
      console.log("price Pick up : ", res.data);
      console.log("price Pick up : ", location.state.pickUp);
    });
  }, []);
  // const dataTarifs = [
  //   {
  //     moreWeek: 1000,
  //     week: 500,
  //     days: 250,
  //   },
  // ];

  const [chechBooking, setChechBooking] = useState([]);
  const [nextStep, setNextStep] = useState(false);

  useEffect(() => {
    let data = [];
    axiosClient.get("/booking/").then((res) => {
      setChechBooking(
        res.data.filter((item) => {
          const start_date = new Date(item.start_date);
          const end_date = new Date(item.end_date);
          const filterStartDate = new Date(pickDate);
          const filterEndDate = new Date(dropDate);

          return (
            (filterStartDate >= start_date && filterStartDate <= end_date) ||
            (filterEndDate >= start_date && filterEndDate <= end_date)
          );
        })
      );
      setNextStep(true);
    });
  }, []);

  console.log("dataaa : ", chechBooking);

  const [dataCars, setDataCars] = useState([]);
  useEffect(() => {
    // if (nextStep) {
    //   if (chechBooking.length > 0) {
    //     let CarsBooking=[]
    //     for (let index = 0; index < chechBooking.length; index++) {
    //       CarsBooking.push(chechBooking[index]?.car)
    //     }
    //     console.log('cars booking : ',CarsBooking);
    //     axiosClient.get('/cars/').then(res=>{
    //       setDataCars(res.data.filter(e=>!CarsBooking.includes(e.id_car)))
    //     })
    //   }
    // }

    axiosClient.get("/cars/").then((res) => {
      setDataCars(res.data.filter(e=>e.disponible === true));
    });
  }, []);

  // console.log("caaaaaaaars : ", dataCars);

  function getGeneralPrice(id, days) {
    const tarifCar = dataTarifs?.find((e) => e.car === id);
    console.log("idddd: ,", id);
    console.log("taaarif  : ", tarifCar);
    let price;
    if (days <= 3) {
      price = tarifCar?.price_tree_days * days;
      return price;
    }
    if (days > 3 && days <= 7) {
      price = tarifCar?.price_week * days;
      return price;
    }
    if (days > 7 && days <= 15) {
      price = tarifCar?.price_two_week * days;
      return price;
    }
    if (days > 15 && days <= 30) {
      price = tarifCar?.price_more_two_week * days;
      return price;
    }
    if (days > 30) {
      price = tarifCar?.price_more_month * days;
      return price;
    }
  }

  const [days, setDays] = useState();
  const [price, setPrice] = useState();

  const [modal, setModal] = useState(false); //  class - active-modal

  const showDetails = () => {
    setModal(!modal);
    const modalDiv = document.querySelector(".booking-modal");
    modalDiv.scroll(0, 0);
  };

  useEffect(() => {
    const start = new Date(pickDate);
    const end = new Date(dropDate);

    if (!isNaN(start.getTime()) && !isNaN(end.getTime())) {
      const timeDifference = end.getTime() - start.getTime();
      const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
      setDays(daysDifference);
    }

    //---------------------------------

    if (modal === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modal]);

  const [idCar, setIdCar] = useState();
  const detailsCar = (id) => {
    const dataCar = dataCars.find((e) => e.id_car === id);
    return dataCar;
  };

  console.log("detail car : ", detailsCar(1));

  

  return (
    <>
      <section className="search-page">
        <HeroPages name="Search" />
        <div className="container">
          <div className="models-div">
            {dataCars.map((val, key) => {
              return (
                <div className="models-div__box">
                  <div className="models-div__box__img">
                    <img src={val.image} alt="car_img" />
                    <div className="models-div__box__descr">
                      <div className="models-div__box__descr__name-price">
                        <div
                          className="models-div__box__descr__name-price__name"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <p>
                            {val.brand} {val.model}
                          </p>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: 16,
                              gap: 8,
                              padding: "4px 7px",
                              border: "1px solid gray",
                              borderRadius: 7,
                              cursor: "pointer",
                              boxShadow: "1px 1px 5px gray",
                            }}
                            onClick={() => {
                              showDetails();
                              setIdCar(val.id_car);
                            }}
                          >
                            <TbListDetails
                              className="btn-showDetails"
                              size={16}
                            />
                            <span>{t("Détails")}</span>
                          </div>
                        </div>
                        {/* <div className="models-div__box__descr__name-price__price">
                      <h4>$45</h4>
                      <p>per day</p>
                    </div> */}
                      </div>
                      <div className="models-div__box__descr__name-price__details">
                        <span>{val.passenger_number} {t("Sièges")}</span>
                        <span style={{ textAlign: "right" }}>
                          {t("Année")} {val.year}
                        </span>
                        <span>{val.gearBox}</span>
                        <span style={{ textAlign: "right" }}>{val.fuel}</span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 2,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          className=""
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <strong style={{ fontSize: 15, marginBottom: 10 }}>
                            {getGeneralPrice(val.id_car, days) +
                              parseInt(
                                pricePickUp.find(
                                  (e) => e.id_pickup === parseInt(pickUp)
                                )?.tarif
                              ) +
                              parseInt(
                                pricePickUp.find(
                                  (e) => e.id_pickup === parseInt(dropOff)
                                )?.tarif
                              )}{" "}
                            MAD /{" "}
                            {days === 1 ? `${days} ${t("jour")}` : `${days} ${t("jours")}`}
                          </strong>
                          <button
                            onClick={() => {
                              const carPrice = parseFloat(
                                getGeneralPrice(val.id_car, days) +
                                  parseInt(
                                    pricePickUp.find(
                                      (e) => e.id_pickup === parseInt(pickUp)
                                    )?.tarif
                                  ) +
                                  parseInt(
                                    pricePickUp.find(
                                      (e) => e.id_pickup === parseInt(dropOff)
                                    )?.tarif
                                  )
                              );
                              if (carPrice) {
                                navigate("/reservation", {
                                  state: {
                                    price: carPrice,
                                    pickUp,
                                    dropOff,
                                    pickDate,
                                    pickTime,
                                    dropDate,
                                    dropTime,
                                    days,
                                    idCar: val.id_car,
                                  },
                                });
                              }
                            }}
                            to="/"
                            style={{
                              padding: "4px 15px",
                              width: "100%",
                              borderRadius: "20px",
                              border: "1px solid #eb5151",
                              fontSize: 20,
                              textDecoration: "none",
                              background: "white",
                              cursor: "pointer",
                            }}
                          >
                            {t("Réserver ")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div> 
        <Footer />
      </section>

      {/* modal ------------------------------------ */}

      <div className={`booking-modal ${modal ? "active-modal" : ""}`}>
        {/* title */}
        <div className="booking-modal__title">
          <h2>{t("Détails & spécification")}</h2>
          <i onClick={showDetails} className="">
            <LiaTimesSolid />
          </i>
        </div>
        {/* message */}
        {/* <div className="booking-modal__message">
          <h4>
            <i className="fa-solid fa-circle-info"></i> Upon completing this
            reservation enquiry, you will receive:
          </h4>
          <p>
            Your rental voucher to produce on arrival at the rental desk and a
            toll-free customer support number.
          </p>
        </div> */}
        {/* car info */}
        <div
          className=""
          style={{
            padding: 20,
          }}
        >
          <h5 style={{ textTransform: "uppercase", fontSize: 25 }}>
          {t("Spécification")}
          </h5>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: 15,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 6,
                fontSize: 13,
              }}
            >
              <span>{detailsCar(idCar)?.passenger_number} {t("Sièges")}</span>
              <span>{t("Couleur")} : {detailsCar(idCar)?.color}</span>
              <span>{t("Année")} : {detailsCar(idCar)?.year}</span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 6,
                fontSize: 13,
              }}
            >
              <span>{detailsCar(idCar)?.gearBox}</span>
              <span>
              {t("Climatisation")} :{" "}
                {detailsCar(idCar)?.air_conditioning === true
                  ? "Disponible"
                  : "Indisponible"}
              </span>
              <span>{t("Carburant")} : {detailsCar(idCar)?.fuel}</span>
            </div>
          </div>
        </div>
        <div
          className=""
          style={{
            padding: 20,
          }}
        >
          <h5 style={{ textTransform: "uppercase", fontSize: 25 }}>
          {t("Prix inclut")}
          </h5>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 6,
              padding: 15,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 4,
                alignItems: "center",
                fontSize: 13,
              }}
            >
              <FaCheck color="pink" />
              <span>{t("Caution gratuite")}</span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 4,
                alignItems: "center",
                fontSize: 13,
              }}
            >
              <FaCheck color="pink" />
              <span>{t("Kilométrage illimité")}</span>
            </div>
          </div>
        </div>
        <div
          className=""
          style={{
            padding: 20,
          }}
        >
          <h5 style={{ textTransform: "uppercase", fontSize: 25 }}>
            Conditions
          </h5>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 6,
              padding: 15,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 4,
                alignItems: "center",
                fontSize: 13,
              }}
            >
              <GrStatusWarning color="black" />
              <span>{t("Âge minimum 23")}</span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 4,
                alignItems: "center",
                fontSize: 13,
              }}
            >
              <GrStatusWarning color="black" />
              <span>{t("CIN / Passeport")}</span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 4,
                alignItems: "center",
                fontSize: 13,
              }}
            >
              <GrStatusWarning color="black" />
              <span>{t("Permis de conduire")}</span>
            </div>
          </div>
        </div>
        {/* 
        <div className="booking-modal__car-info">
          <div className="dates-div">
            <div className="booking-modal__car-info__dates">
              <h5>Location & Date</h5>
              <span>
                <i className="fa-solid fa-location-dot"></i>
                <div>
                  <h6>Pick-Up Date & Time</h6>
                  <p>
                    pick time /{" "}
                    <input type="time" className="input-time"></input>
                  </p>
                </div>
              </span>
            </div>

            <div className="booking-modal__car-info__dates">
              <span>
                <i className="fa-solid fa-location-dot"></i>
                <div>
                  <h6>Drop-Off Date & Time</h6>
                  <p>
                    drop time /{" "}
                    <input type="time" className="input-time"></input>
                  </p>
                </div>
              </span>
            </div>

            <div className="booking-modal__car-info__dates">
              <span>
                <i className="fa-solid fa-calendar-days"></i>
                <div>
                  <h6>Pick-Up Location</h6>
                  <p>pick-up location</p>
                </div>
              </span>
            </div>

            <div className="booking-modal__car-info__dates">
              <span>
                <i className="fa-solid fa-calendar-days"></i>
                <div>
                  <h6>Drop-Off Location</h6>
                  <p>drop off</p>
                </div>
              </span>
            </div>
          </div>
          <div className="booking-modal__car-info__model">
            <h5>
              <span>Car -</span> cat type
            </h5>
            image
          </div>
        </div> */}
        {/* personal info */}
        {/* <div className="booking-modal__person-info">
          <h4>Personal Information</h4>
          <form className="info-form">
            <div className="info-form__2col">
              <span>
                <label>
                  First Name <b>*</b>
                </label>
                <input
                  value={name}
                  onChange={handleName}
                  type="text"
                  placeholder="Enter your first name"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Last Name <b>*</b>
                </label>
                <input
                  value={lastName}
                  onChange={handleLastName}
                  type="text"
                  placeholder="Enter your last name"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>

              <span>
                <label>
                  Phone Number <b>*</b>
                </label>
                <input
                  value={phone}
                  onChange={handlePhone}
                  type="tel"
                  placeholder="Enter your phone number"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Age <b>*</b>
                </label>
                <input
                  value={age}
                  onChange={handleAge}
                  type="number"
                  placeholder="18"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
            </div>

            <div className="info-form__1col">
              <span>
                <label>
                  Email <b>*</b>
                </label>
                <input
                  value={email}
                  onChange={handleEmail}
                  type="email"
                  placeholder="Enter your email address"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Address <b>*</b>
                </label>
                <input
                  value={address}
                  onChange={handleAddress}
                  type="text"
                  placeholder="Enter your street address"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
            </div>

            <div className="info-form__2col">
              <span>
                <label>
                  City <b>*</b>
                </label>
                <input
                  value={city}
                  onChange={handleCity}
                  type="text"
                  placeholder="Enter your city"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Zip Code <b>*</b>
                </label>
                <input
                  value={zipcode}
                  onChange={handleZip}
                  type="text"
                  placeholder="Enter your zip code"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
            </div>

            <span className="info-form__checkbox">
              <input type="checkbox"></input>
              <p>Please send me latest news and updates</p>
            </span>

          </form>
        </div> */}
      </div>
    </>
  );
}

export default SearchCar;
