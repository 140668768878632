import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import PlanTrip from "../components/PlanTrip";
import AboutMain from "../images/about/about-main.jpg";
import Box1 from "../images/about/icon1.png";
import Box2 from "../images/about/icon2.png";
import Box3 from "../images/about/icon3.png";

function About() {
  const { t } = useTranslation();
  return (
    <>
      <section className="about-page">
        <HeroPages name={t("À propos")} />
        <div className="container">
          <div className="about-main">
            <img
              className="about-main__img"
              src={AboutMain}
              alt="car-renting"
            />
            <div className="about-main__text">
              <h3>{t("À propos de l'agence")}</h3>
              <h2>
                {t("Vous mettez en marche le moteur, et c'est le début de l'aventure")}
              </h2>
              <p>
                {t("Bienvenue chez Australie Cars, une référence en matière de location de voitures basée à Agadir depuis 6 ans. Nous sommes fiers de vous proposer une expérience de location exceptionnelle. Depuis notre création, nous nous engageons à fournir des services de qualité, des véhicules fiables et des solutions flexibles à nos clients. Avec une solide présence dans le secteur, nous sommes votre partenaire de confiance pour des voyages inoubliables au Maroc")}.
              </p>
              <div className="about-main__text__icons">
                <div className="about-main__text__icons__box">
                  <img src={Box1} alt="car-icon" />
                  <span>
                    <h4>13</h4>
                    <p>{t("Types de voitures")}</p>
                  </span>
                </div>
                <div className="about-main__text__icons__box">
                  <img src={Box2} alt="car-icon" />
                  <span>
                    <h4>9</h4>
                    <p>{t("Point de relais")}</p>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <PlanTrip />
        </div>
      </section>
      {/* <div className="book-banner">
        <div className="book-banner__overlay"></div>
        <div className="container">
          <div className="text-content">
            <h2>Book a car by getting in touch with us</h2>
            <span>
              <i className="fa-solid fa-phone"></i>
              <h3>(123) 456-7869</h3>
            </span>
          </div>
        </div>
      </div> */}
      <Footer />
    </>
  );
}

export default About;
