import PropTypes from "prop-types";
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MdTranslate, MdLanguage } from 'react-icons/md';
import Flags from "country-flag-icons/react/3x2";
import { useTranslation } from "react-i18next";

const LanguageChanger = ({ currency }) => {
    const{t} = useTranslation();
    const { i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('i18nextLng') || 'fr'); // Default language

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLanguageChange = (language) => {
        // Implement language change logic here
        console.log(`Selected language: ${language}`);
        setSelectedLanguage(language);
        const languageCode = language;
        i18n.changeLanguage(languageCode);
        handleClose();
    };

    const Flag = ({ countryCode }) => {
        const FlagComponent = Flags[countryCode.toUpperCase()];

        if (!FlagComponent) {
            // Handle the case where the country code is not valid or not supported
            return null;
        }

        return <FlagComponent style={{ width: '24px', height: 'auto' }} />;
    };

    return (
        <>
            <Button
                sx={{ color: '#000000', fontSize: '25px' }}
                aria-controls="language-menu"
                aria-haspopup="true"
                onClick={handleClick}
                startIcon={<MdTranslate style={{ color: '#000000', fontSize: '22px' }} />}
            >
                <MdLanguage />
            </Button>
            <Menu
                id="language-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >

                <MenuItem onClick={handleClose} disabled>{t("Langue")}</MenuItem>
                <MenuItem onClick={() => handleLanguageChange('fn')} style={{ display: 'flex', alignItems: 'center' }}>
                    <Flag countryCode={"FR"} /> &nbsp;&nbsp;  Français

                </MenuItem>
                <MenuItem onClick={() => handleLanguageChange('en')} style={{ display: 'flex', alignItems: 'center' }}>
                    <Flag countryCode={"US"} /> &nbsp;&nbsp;  English

                </MenuItem>
            </Menu>
        </>
    );
};

LanguageChanger.propTypes = {
    currency: PropTypes.shape({}),
};

export default LanguageChanger;
